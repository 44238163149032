import React,{useEffect,useRef} from 'react'
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link, useNavigate,useLocation } from 'react-router-dom';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

import { useMaterialUIController } from "context";
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import DataTable from "examples/PrimeReactDatatable";
import { useState } from "react";
import { useAuth } from '../../context/AuthContext';

function Categories() {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { auth } = useAuth();
  const [apiResponse, setApiResponse] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  //const {restaurant_Id} = auth;
  const prevApiResponse = useRef(null);
  const location = useLocation();
  const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
  const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
  const restaurant_Id = globalAuthString.restaurant_Id?globalAuthString.restaurant_Id:null;
  const restId = restaurant_Id ? restaurant_Id : (localAuthString ? localAuthString.restaurant_Id : null);

  const formattedDate = (date) => {
    const d = new Date(date);
    if (isNaN(d.getTime())) {
        // If the date is invalid, return 'Invalid Date'
        console.error('Invalid date:', date);
        return 'Invalid Date';
    } else {
        // Format the date as needed
        const month = ('0' + (d.getMonth() + 1)).slice(-2);
        const day = ('0' + d.getDate()).slice(-2);
        const year = d.getFullYear();
        return [year, month, day].join('-');
    }
  }

    const fetchcategories = () => {
      axios.get(`/product_promotions/restaurant/${restId}`)
        .then(response => {
  console.log("restId",restId);
          const data = response.data;
          console.log(data);
          setApiResponse(data);
  
        })
        .catch(error => console.error('Error fetching categories:', error));
    };
    useEffect(() => {


    fetchcategories();
  }, []);

  useEffect(() => {

  }, [apiResponse]);


  const handleDeleteCategory = (itemId, promotionId, restaurantId) => {
    axios.delete(`/product_promotions/${itemId}/${promotionId}/${restaurantId}`)
      .then(response => {
        console.log(response.data);
        // Refresh the task list after deletion
        fetchcategories();
        alert("Promotion Deactivated Successfully!");
        //window.location.reload(); 
  
            
      })
      .catch(error => {
        console.error('Error Deactivating Promotion:', error);
        alert("Error Deactivating Promotion");
       
      });
      
  };

 
  const [tableData, setTableData] = useState({
    columns: [
        { field: "item_id", header: "Food Item ID", sortable: true},
      { field: "item_name", header: "Food Item Name", sortable: true},
      { field: "promotion_id", header: "Promotion ID", sortable: true},
      { field: "promotion_name", header: "Promotion Name", sortable: true},
      //{ field: "date", header: "Start Date", sortable: true ,dataType:"date"},
      { field: "date", header: "End Date", sortable: true,dataType:"date"},
       { field: "action", header: "action", sortable: false,},
    ],
    items: [
    
      // {id: 4, name: 'Pizzas', status: 1, description: 'Pizzas Category', created_on: '2024-04-22T17:37:57.000Z'},
      // {id: 5, name: 'Sandwich', status: 1, description: 'Sandwich', created_on: '2024-04-22T18:29:13.000Z'}
     
    ],
    paginator: true,
    rows: 10,
    resizableColumns: true,
    showGridlines: true,
    loading: false,
    emptyMessage: "No data found."
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
    
            axios.get(`/product_promotions/restaurant/${restId}`)
              .then(response => {
    
                const data = response.data;
               console.log(response.data);
  
        // Format the fetched data for display in the table
        const formattedData = data.map(item => ({
          item_id: item.food_item.id,
          item_name: item.food_item.title,
          promotion_id: item.promotions.id,
          promotion_name: item.promotions.promotion_name,
        //  date: item.promotions.start_date,
          date: item.promotions.end_date,
          action: 
          (    
             <MDButton variant="text" color="error"onClick={() => handleDeleteCategory(item.item_id,item.promotion_id,restId)}>
          <Icon>delete</Icon>&nbsp;delete
        </MDButton>
          )
      
        }));
             
        // Update the table data state with the formatted data
        setTableData(prevState => ({
          ...prevState,
          items: formattedData,
        }));
    })
      } catch (error) {
        console.error("Error fetching data:", error);
      }

    };
  
    // Call the fetchData function when the component mounts
    fetchData();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <button type="button" className="btn btn-primary mt-3 ms-5" data-mdb-ripple-init="true">
  <Link to="/activate-promotion" className="text-white text-decoration-none">Add New</Link>
</button>
      <MDBox pt={6} pb={3}>
        
        <Grid container spacing={6}>
          <Grid item xs={12}>
         
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                 Active Promotions List
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
       tableData={tableData}
      //  //onEditItem={handleEditCategory}
      //  onDeleteItem={handleDeleteCategory}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    
    </DashboardLayout>
  );
}

export default Categories;
