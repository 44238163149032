import React, { createContext, useContext, useState,  useDebugValue, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [auth, setAuth] = useState({});

  const login = () => setAuthenticated(true);
  const login1 = ({empId, userRole}) => setAuth({empId, userRole});
  const logout = () => setAuth({});


  // useEffect(() => {
  //   (async () => {
  //     const authResult = await axios.get('http://localhost:3001/auth/me');

  //     if (authResult) {
  //       setAuth(authResult.data);
  //       setAuthenticated(true);
  //     }
  //     setAuthenticated(false);

  //   })()
  // }, []);
  
  const loadUser = async () => {
    const token = JSON.parse(sessionStorage.getItem('auth'));
  
    if (!token) {
     console.log("No auth found")
    }
    setAuth(token);
  
  };

  return (
    <AuthContext.Provider value={{auth, setAuth, login, loadUser}}>
      {children}
    </AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired, // Validate that children is a node and is required
};
export const useAuth = () => {
    const { auth } = useContext(AuthContext);
    useDebugValue(auth, auth => auth?.emp_id ? "Logged In" : "Logged Out")
    return useContext(AuthContext);
}