/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
    MDBRow,
    MDBCol,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBCard,
    MDBFile
  } from 'mdb-react-ui-kit';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";


function Overview() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3} className="p-2">
 
            {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
      
            
              <MDBCard  className="p-5"> 
               <form>
               <MDBRow className='mb-4'>
                 <MDBCol>
                   <MDBInput id='form6Example1' label='First name' />
                 </MDBCol>
                 <MDBCol>
                   <MDBInput id='form6Example2' label='Last name' />
                 </MDBCol>
               </MDBRow>
               <MDBRow className='mb-4'>
                 <MDBCol>
                   <MDBInput id='form6Example1' label='Gender' />
                 </MDBCol>
                 <MDBCol>
                   <MDBInput id='form6Example2' label='Age' />
                 </MDBCol>
               </MDBRow>
               <MDBRow className='mb-4'>
                 <MDBCol>
                 <MDBInput wrapperClass='mb-4' type='email' id='form6Example5' label='Email' />
                 </MDBCol>
                 <MDBCol>
                 <MDBInput wrapperClass='mb-4' type='tel' id='form6Example6' label='Phone' />
                 </MDBCol>
               </MDBRow>
               <MDBRow className='mb-4'>
                 <MDBCol>
                   <MDBInput id='form6Example1' label='Time' />
                 </MDBCol>
                 <MDBCol>
                 <select className="browser-default custom-select" id="package"> 
           <option selected></option>
           <option value="1">One</option>
           <option value="2">Two</option>
           <option value="3">Three</option>
         </select> 
         <label className="form-label" htmlFor="package">Package</label>
                 </MDBCol>
               </MDBRow>
         
               <MDBInput wrapperClass='mb-4' id='form6Example4' label='Address' />
               <MDBInput wrapperClass='mb-4' id='form6Example3' label='Country' />
               <MDBInput wrapperClass='mb-4' id='form6Example3' label='City' />
            
               <div className="form-outline mb-4">
             <input type="number" id="typeNumber" className="form-control" />
             <label className="form-label" htmlFor="typeNumber">Employees Limit</label>
         </div>
         
               <MDBInput wrapperClass='mb-4' textarea id='form6Example7' rows={4} label='National Identity Number' />
               <div className="form-outline mb-4">
               <MDBFile  id='formFileMultiple' multiple  rows={4}  />
               <label className="form-label" htmlFor="formFileMultiple">Upload Signed Documents</label>
               </div>
               <MDBBtn className='mb-4 mt-4' >
                 Download Terms & Conditions Document
               </MDBBtn>
               {/* <MDBCheckbox
                 wrapperClass='d-flex justify-content-center mb-4'
                 id='form6Example8'
                 label='Create an account?'
                 defaultChecked
               /> */}
         
               <MDBBtn className='mb-4' type='submit' block>
                 Create
               </MDBBtn>
             </form>
              </MDBCard>
              
         
           
     
        </MDBox>
       
      </Header>

    </DashboardLayout>
  );
}

export default Overview;
