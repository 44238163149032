import React, { useState, useEffect } from 'react';
import {
    MDBRow,
    MDBCol,
    MDBInput,
    MDBCard,
    MDBTypography,
} from 'mdb-react-ui-kit';
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { FaEye, FaEyeSlash, FaTrash, FaPlus } from 'react-icons/fa';
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import { useFormik, Field, ErrorMessage, FormikProvider } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useAuth } from '../../context/AuthContext';
//import axios from 'axios';



export default function AddItem() {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const taskId = searchParams.get('taskId');
    const axios = useAxiosPrivate();
    const { auth } = useAuth();
    const { restaurantDetail } = auth;


    const [UpdateData, setUpdateData] = useState([]);
    const isUpdateForm = Object.keys(UpdateData).length > 0;
    //const {emp_id}= auth;
    const config = {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
    const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
    const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
    const emp_id = globalAuthString.emp_id ? globalAuthString.emp_id : null;
    const empId = localAuthString ? localAuthString.emp_id : (emp_id ? emp_id : null);


    useEffect(() => {
        if (taskId) {
            axios.get(`/restaurants/${taskId}`)
                .then(response => {
                    console.log(response.data);
                    const data = response.data.data;
    
                    if (data) {
                        setUpdateData(data);
                        const initialValues = {
                            admin_Id: empId,
                            name: data.name || '',
                            description: data.description || '',
                            address_line1: data.address_line1 || '',
                            address_line2: data.address_line2 || '',
                            city: data.city || '',
                            area: data.area || '',
                            contact_number: data.contact_number || '',
                            email: data.email || '',
                            opening_hours: data.opening_hours || '',
                            closing_hours: data.closing_hours || '',
                            is_active: data.is_active ?? null,
                            restaurantImage: data.logo_path || '',
                        };
                        formik.setValues(initialValues);
                    } else {
                        console.log("No data found for task ID:", taskId);
                    }
                })
                .catch(error => {
                    console.error('Error fetching record for task ID:', error);
                });
        } else {
            axios.get(`/restaurants/users/${empId}`)
                .then(response => {
                    console.log(response.data);
                    const data = response.data.data;
    
                    if (data) {
                        setUpdateData(data);
                        const initialValues = {
                            admin_Id: empId,
                            name: data.name || '',
                            description: data.description || '',
                            address_line1: data.address_line1 || '',
                            address_line2: data.address_line2 || '',
                            city: data.city || '',
                            area: data.area || '',
                            contact_number: data.contact_number || '',
                            email: data.email || '',
                            opening_hours: data.opening_hours || '',
                            closing_hours: data.closing_hours || '',
                            is_active: data.is_active ?? null,
                            restaurantImage: data.logo_path || '',
                        };
                        formik.setValues(initialValues);
                    } else {
                        console.log("No data found for user ID:", empId);
                    }
                })
                .catch(error => {
                    console.error('Error fetching record for user ID:', error);
                });
        }
    }, [taskId, empId]);
    

    const addRecord = async (values) => {

        try {
            console.log("Making a POST request to fooditems with data:", values);

            const response = await axios.post('/restaurants', values, config);

            console.log("Response status:", response.status);
            console.log("Response data:", response.data);
            alert("Added Successfully!");
            setTimeout(function () {

                // handleButtonClick();
            }, 3000);
        } catch (error) {
            console.error("Error while making the POST request:", error.message);
            alert("Error Adding Record");
        }
    };

    const updateRecord = async (values) => {

        try {
            console.log(`Making a PUT request to fooditems with data:`, values);

            const response = await axios.put(`/restaurants/${UpdateData.id}`, values, config);

            console.log("Response status:", response.status);
            console.log("Response data:", response.data);
            alert("Updated Successfully!");
            setTimeout(function () {

                //  handleButtonUpdate();
            }, 3000);
        } catch (error) {

            alert("Error Updating Record");
        }
    };



    const formik = useFormik({
        initialValues: {
            admin_Id: empId,
            name: '',
            description: '',
            address_line1: '',
            address_line2: '',
            city: '',
            area: '',
            contact_number: '',
            email: '',
            opening_hours: '',
            closing_hours: '',
            is_active: null,
            restaurantImage: '',
        
        },

        validationSchema: Yup.object({


            name: Yup.string().required('This field is required'),
            description: Yup.string().required('This field is required'),
            address_line1: Yup.string().required('This field is required'),
            address_line2: Yup.string().required('This field is required'),
            city: Yup.string().required('This field is required'),
            area: Yup.string().required('This field is required'),
            contact_number: Yup.string().required('This field is required'),
            email: Yup.string().required('This field is required'),
            opening_hours: Yup.string().required('This field is required'),
            closing_hours: Yup.string().required('This field is required'),
            is_active: Yup.number().required('This field is required'),

        }),
        onSubmit: (values) => {
           
            isUpdateForm ? updateRecord(values) : addRecord(values);

            navigate('/kitchen-admin-dashboard');

        },
    });

    return (
        <>

            <DashboardLayout>
                <DashboardNavbar />
                <FormikProvider value={formik}>

                    < MDBCard className='p-5 mt-3'>
                        <form onSubmit={formik.handleSubmit}>

                            <MDBRow className='mb-4'>
                                <MDBCol>
                                    <Form.Group controlId="name">
                                        <label className="form-label" htmlFor="title">Name</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Name"
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.name && formik.errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </MDBCol>
                                <MDBCol>
                                    <Form.Group controlId="address_line1">
                                        <label className="form-label" htmlFor="address_line1">Address Line 1</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Address"
                                            name="address_line1"
                                            value={formik.values.address_line1}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.address_line1 && formik.errors.address_line1}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.address_line1}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </MDBCol>
                            </MDBRow>

                            <MDBRow className='mb-4'>
                                <MDBCol>
                                    <Form.Group controlId="address_line2">
                                        <label className="form-label" htmlFor="address_line2">Address Line 2</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Address"
                                            name="address_line2"
                                            value={formik.values.address_line2}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.address_line2 && formik.errors.address_line2}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.address_line2}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </MDBCol>
                                <MDBCol>
                                    <Form.Group controlId="is_active">
                                        <label className="form-label" htmlFor="is_active">Status</label>
                                        <Field
                                            as="select"
                                            className="browser-default custom-select"
                                            id="is_active"
                                            name="is_active"
                                            value={formik.values.is_active}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.is_active && formik.errors.is_active}
                                        >
                                            <option value="" disabled selected>Select status</option>
                                            <option value={1}>Active</option>
                                            <option value={0}>InActive</option>

                                        </Field>
                                        <small><ErrorMessage name="is_active" component="div" className="text-danger" /></small>
                                    </Form.Group>
                                </MDBCol>
                            </MDBRow>


                            <MDBRow className='mb-4'>
                                <MDBCol>
                                    <Form.Group controlId="email">
                                        <label className="form-label" htmlFor="email">Email</label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter Email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.email && formik.errors.email}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </MDBCol>
                                <MDBCol>
                                    <Form.Group controlId="contact_number">
                                        <label className="form-label" htmlFor="contact_number">Contact Number</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Contact"
                                            name="contact_number"
                                            value={formik.values.contact_number}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.contact_number && formik.errors.contact_number}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.contact_number}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </MDBCol>
                            </MDBRow>


                            <MDBRow className='mb-4'>
                                <MDBCol>
                                    <Form.Group controlId="opening_hours">
                                        <label className="form-label" htmlFor="opening_hours">Opening Hours</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Opening Hours"
                                            name="opening_hours"
                                            value={formik.values.opening_hours}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.opening_hours && formik.errors.opening_hours}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.opening_hours}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </MDBCol>
                                <MDBCol>
                                    <Form.Group controlId="closing_hours">
                                        <label className="form-label" htmlFor="closing_hours">Closing Hours</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Closing Hours"
                                            name="closing_hours"
                                            value={formik.values.closing_hours}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.closing_hours && formik.errors.closing_hours}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.closing_hours}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </MDBCol>
                            </MDBRow>



                            <MDBRow className='mb-4'>
                                <MDBCol>
                                    <Form.Group controlId="description">
                                        <label className="form-label" htmlFor="description">Description</label>
                                        <textarea
                                            id="description"
                                            name="description"
                                            rows={1}
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="form-control"
                                        // You can add more props as needed
                                        />
                                        <small> <ErrorMessage name="description" component="div" className="text-danger" /></small>
                                    </Form.Group>
                                </MDBCol>
                                <MDBCol>
                                    <Form.Group controlId="restaurantImage">
                                        <label className="form-label" htmlFor="restaurantImage">Featured Image</label>
                                        <input
                                            type="file"
                                            name="restaurantImage"
                                            accept="image/*"
                                            onChange={(event) => formik.setFieldValue('restaurantImage', event.currentTarget.files[0])}
                                            className={` inputclass form-control ${formik.touched.restaurantImage && formik.errors.restaurantImage ? 'is-invalid' : ''
                                                }`}
                                        />
                                        <div className="invalid-feedback">{formik.errors.restaurantImage}</div>
                                    </Form.Group>
                                </MDBCol>
                            </MDBRow>




                            {/* <MDBTypography>Delivery Area</MDBTypography> */}
                            <MDBRow className='mb-4'>
                                <MDBCol>
                                    <Form.Group controlId="city">
                                        <label className="form-label" htmlFor="is_active">City</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter City"
                                            name="city"
                                            value={formik.values.city}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.city && formik.errors.city}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.city}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </MDBCol>
                                <MDBCol>
                                    <Form.Group controlId="area">
                                        <label className="form-label" htmlFor="area">Area</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Area"
                                            name="city"
                                            value={formik.values.area}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.area && formik.errors.area}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.area}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </MDBCol>
                            </MDBRow>


                            <button className='mb-4 mt-5 btn btn-primary w-100' type='submit' >
                                {isUpdateForm ? 'Update' : 'Add'}
                            </button>
                        </form>
                    </MDBCard>
                </FormikProvider>
            </DashboardLayout>

        </>
    );
}