import React,{useState, useEffect, useRef} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

//import DataTable from "examples/Tables/DataTable";
import DataTable from "examples/PrimeReactDatatable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import { useMaterialUIController } from "context";
import { Link ,useNavigate,useLocation } from 'react-router-dom';
import { Restaurant } from "@mui/icons-material";
import { useAuth } from '../../context/AuthContext';
function RestaurantList() {
  const location = useLocation();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { auth , setAuth} = useAuth();
  const [apiResponse, setApiResponse] = useState([]);
  const { restaurantDetail } = auth;
  const prevApiResponse = useRef(null);

  const fetchList = () => {
    axios.get(`/restaurants`)
      .then(response => {

        const data = response.data.data;
        console.log(data);
        setApiResponse(data);

      })
      .catch(error => console.error('Error fetching categories:', error));
  };
  useEffect(() => {


    fetchList();
  }, []);



  const handleDeleteCategory = (taskId) => {
    axios.delete(`/restaurants/${taskId}`)
      .then(response => {
        console.log(response.data);
        // Refresh the task list after deletion
        fetchList();
        alert("Record deleted successfully!");

      })
      .catch(error => {
        console.error('Error deleting category:', error);
        alert("Error deleting record");

      });

  };
  const handleEditItem = (taskId) => {
    // Navigate to the "add-item" URL with the taskId as a query parameter
    navigate(`/add-restaurant?taskId=${taskId}`);
  };

  const handleViewDashboard = (taskId) => {
    // Navigate to the "add-item" URL with the taskId as a query parameter
    navigate(`/add-restaurant?taskId=${taskId}`);
  };

  const [tableData, setTableData] = useState({
    columns: [
      { field: 'id', header: 'Id', sortable: true },
      { field: 'name', header: 'Name', sortable: true },
      //{ field: 'contact', header: 'Contact Number', sortable: true },
      //{ field: 'email', header: 'Contact Email', sortable: true },
      //{ field: 'description', header: 'Description', sortable: true },
      { field: 'admin', header: 'Kitchen Admin', sortable: true },
      { field: 'date', header: 'Created On', sortable: true, dataType: "date" },
      { field: 'areas', header: 'Operating Area', sortable: true },
      { field: 'city', header: 'Operating City', sortable: true },
      //{ field: 'status', header: 'Status', sortable: false },
      { field: 'action', header: 'Action', sortable: false },
      { field: 'view', header: 'View Dashboard', sortable: false },
    ],
    items: [

    ],
    paginator: true,
    rows: 10,
    resizableColumns: true,
    showGridlines: true,
    loading: false,
    emptyMessage: "No data found."
  });
  useEffect(() => {
    console.log("response:", apiResponse); // Log updated apiResponse

    if (apiResponse !== prevApiResponse.current) {
      // Update prevApiResponse ref
      prevApiResponse.current = apiResponse;
      const updatedRows = apiResponse?.map((item, index) => ({
        id:item.id,
        name: item.name,
        email: item.email,
        contact:item.contact_number,
        description:item.description,
        admin: item.user_restaurant_admin_IdTouser.firstName,
        date: item.date,
        areas: item.area,
        city: item.city,
        status: item.is_active === 1 ? "Active" : "Not Active",
        date: item.created_at,

        action: (
          <>
            <MDButton variant="text" color="error" onClick={() => handleDeleteCategory(item.id)}>
              <Icon>delete</Icon>&nbsp;delete
            </MDButton>
            <MDButton variant="text" color="success" onClick={() => handleEditItem(item.id)}>
              <Icon>edit</Icon>&nbsp;edit
            </MDButton>
          </>
        ),
        view: (
          <>
           <MDButton variant="text" color="info" 
           onClick={() => 
            { //setAuth({ ...auth, userRole: 'Kitchen Admin',emp_id:item.user_restaurant_admin_IdTouser.id, restaurant_Id:item.id});
                          sessionStorage.setItem('kitchenAdminAuth', JSON.stringify({ emp_id:item.user_restaurant_admin_IdTouser.id, userRole: 'Kitchen Admin',restaurant_Id:item.id}));
                        
                          navigate('/kitchen-admin-dashboard', { replace: true });
                           }}>

              <Icon>eye</Icon>&nbsp;View
            </MDButton>
          </>
        ),
      }));

      console.log("updatedRows", updatedRows);
      // Update tableData with new rows
      setTableData(prevTableData => ({
        ...prevTableData,
        items: updatedRows,
      }));
      console.log(tableData);
    }
  }, [apiResponse]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <button type="button" className="btn btn-primary my-3 ms-5" data-mdb-ripple-init="true">
        <Link to="/add-restaurant" className="text-white text-decoration-none">Add New</Link>
      </button>
      <MDBox pt={6} pb={3}>

        <Grid container spacing={6}>
          <Grid item xs={12}>

            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Resturant List
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  tableData={tableData}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

    </DashboardLayout>
  );
}

export default RestaurantList;
