import React,{useEffect,useRef} from 'react'
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link, useNavigate } from 'react-router-dom';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

import { useMaterialUIController } from "context";
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import DataTable from "examples/PrimeReactDatatable";
import { useState } from "react";
import { useAuth } from '../../context/AuthContext';

function Areas() {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { auth } = useAuth();
  const [apiResponse, setApiResponse] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const {restaurantDetail} = auth;
  const prevApiResponse = useRef(null);


  const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
  const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
  const restaurantId = globalAuthString.restaurant_Id ? globalAuthString.restaurant_Id : null;
  const restId = restaurantId ? restaurantId : (localAuthString ? localAuthString.restaurantId : null);

    const fetchareas = () => {
      axios.get(`/area-by-restaurantId/${restId}`)
        .then(response => {
  
          const data = response.data.data;
          console.log(data);
          setApiResponse(data);
  
        })
        .catch(error => console.error('Error fetching areas:', error));
    };

    const fetchAllUsers = () => {
      axios.get(`/area`)
        .then(response => {
  
          const data = response.data;
          console.log(data);
          setApiResponse(data.data);
  
        })
        .catch(error => console.error('Error fetching users:', error));
  
    };

    useEffect(() => {
      if (globalAuthString?.userRole === "Super Admin") {
        fetchAllUsers();
      }
      else{

    fetchareas();}
  }, []);

  useEffect(() => {

  }, [apiResponse]);

  const handleDeletearea = (taskId) => {
    axios.delete(`/area/${taskId}`)
      .then(response => {
        console.log(response.data);
        // Refresh the task list after deletion
        fetchareas();
        alert("Record deleted successfully!");
        
      })
      .catch(error => {
        console.error('Error deleting area:', error);
        alert("Error deleting record");
       
      });
      
  };
  const handleEditItem = (taskId) => {
    // Navigate to the "add-item" URL with the taskId as a query parameter
    navigate(`/add-area?taskId=${taskId}`);
  };
 
  const [tableData, setTableData] = useState({
    columns: [
        { field: "id", header: "ID", sortable: true},
      { field: "name", header: "Name", sortable: true},
      { field: "status", header: "Status", sortable: true},
      { field: "city", header: "City", sortable: true},
      { field: "restaurant_Id", header: "Restaurant Id", sortable: true},
      { field: "date", header: "Created On", sortable: true,dataType:"date"},
      { field: "action", header: "action", sortable: false,},
    ],
    items: [
    
      // {id: 4, name: 'Pizzas', status: 1, description: 'Pizzas area', created_on: '2024-04-22T17:37:57.000Z'},
      // {id: 5, name: 'Sandwich', status: 1, description: 'Sandwich', created_on: '2024-04-22T18:29:13.000Z'}
     
    ],
    paginator: true,
    rows: 10,
    resizableColumns: true,
    showGridlines: true,
    loading: false,
    emptyMessage: "No data found."
  });
  useEffect(() => {
    console.log("response:", apiResponse); // Log updated apiResponse
    
    if (apiResponse !== prevApiResponse.current) {
      // Update prevApiResponse ref
      prevApiResponse.current = apiResponse;
    const updatedRows = apiResponse?.map((item, index) => ({
      id: item.id,
      name: item.name,
      status: item.is_active===1?"Active":"Not Active",
      city: item.city.name,
      restaurant_Id:item.restaurant_Id,
      date: item.created_at,
    
      action: (
        <>
          <MDButton variant="text" color="error"onClick={() => handleDeletearea(item.id)}>
            <Icon>delete</Icon>&nbsp;delete
          </MDButton>
          <MDButton variant="text" color="success" onClick={() => handleEditItem(item.id)}>
            <Icon>edit</Icon>&nbsp;edit
          </MDButton>
        </>
      ),
    }));
 
  console.log("updatedRows",updatedRows);
    // Update tableData with new rows
    setTableData(prevTableData => ({
      ...prevTableData,
      items: updatedRows,
    }));
    console.log(tableData);
  }
  }, [apiResponse]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <button type="button" className="btn btn-primary mt-3 ms-5" data-mdb-ripple-init="true">
  <Link to="/add-area" className="text-white text-decoration-none">Add New</Link>
</button>
      <MDBox pt={6} pb={3}>
        
        <Grid container spacing={6}>
          <Grid item xs={12}>
         
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Area List
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
       tableData={tableData}
      //  //onEditItem={handleEditarea}
      //  onDeleteItem={handleDeletearea}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    
    </DashboardLayout>
  );
}

export default Areas;
