
import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
//React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import { useAuth } from '../../context/AuthContext';
import { Link, useNavigate } from "react-router-dom";

function FoodItems() {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { auth } = useAuth();
  const [apiResponse, setApiResponse] = useState([]);
  //const { restaurant_Id } = auth;

  const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
  const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
  const restaurant_Id = globalAuthString.restaurant_Id?globalAuthString.restaurant_Id:null;
  const restId = restaurant_Id ? restaurant_Id : (localAuthString ? localAuthString.restaurant_Id : null); 

  const fetchItems = () => {
    console.log("localAuthString",localAuthString);
    console.log("restId",restId);
    axios.get(`/food-items/restaurant/${restId}`)
      .then(response => {
        const data = response.data;
        console.log(data.data);
        console.log("Type of array", typeof (data));

        setApiResponse(data.data); // If response.data is not an array

      })
      .catch(error => console.error('Error fetching records:', error));
  };

  useEffect(() => {


    fetchItems();
  }, []);

  useEffect(() => {
    console.log("Items stored:", apiResponse);

  }, [apiResponse]);

  const handleDeleteItem = (taskId) => {
    axios.delete(`/food_items/${taskId}`)
      .then(response => {
        console.log(response.data);
        // Refresh the task list after deletion
        fetchItems();
        alert("Record deleted successfully!");

      })
      .catch(error => {
        console.error('Error deleting record:', error);
        alert("Error deleting record");

      });

  };

  const handleEditItem = (taskId) => {
    // Navigate to the "add-item" URL with the taskId as a query parameter
    navigate(`/add-food-item?taskId=${taskId}`);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <button type="button" className="btn btn-primary mt-3 ms-5" data-mdb-ripple-init="true">
  <Link to="/add-food-item" className="text-white text-decoration-none">Add New</Link>
</button>
      <MDBox mb={2} />
      <Card
        sx={{
          position: "relative",
          mt: 5,
          mx: 0,
          py: 2,
          px: 5,
        }}
      >
        <MDBox
          mx={0}
          mt={-3}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            Menu
          </MDTypography>
        </MDBox>
        <Grid container spacing={1} alignItems="center">
          <MDBox pt={2} px={2} lineHeight={1.25}>

            <MDBox mb={5}>

            </MDBox>
          </MDBox>
          <MDBox p={4}>
            <Grid container spacing={20} alignItems="center" justifyContent="between">
              {apiResponse?.map(item => (
                <Grid item xs={12} sm={6} md={6} lg={6} xl={4} key={item.id}>
                  <DefaultProjectCard
                    image={`/uploads/${item.image_pathLocation}`}
                    label={item.food_item_category?.name}
                    title={item.title}
                    description={item.description}
                    action={{
                      type: "internal",
                      route: "",
                      color: "success",
                      label: item.price,
                    }}
                    onEdit={() => handleEditItem(item.id)}
                    onDelete={() => handleDeleteItem(item.id)}
                  />
                </Grid>
              ))}
            </Grid>
          </MDBox>
        </Grid>

      </Card>

    </DashboardLayout>
  );
}

export default FoodItems;