import React, { useState, useEffect } from 'react';
import {
    MDBRow,
    MDBCol,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBCard,
    MDBFile
} from 'mdb-react-ui-kit';
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import { useFormik, Field, ErrorMessage, FormikProvider } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useAuth } from '../../context/AuthContext';

export default function App() {
    const navigate = useNavigate();

    const axios = useAxiosPrivate();
    const { auth } = useAuth();
   // const { restaurant_Id } = auth;
    const [promotionData, setpromotionData] = useState([]);
    const [foodItemsData, setFoodItemsData] = useState([]);
    const isUpdateForm = Object.keys(promotionData).length > 0;
    //const isUpdateForm = Object.keys(promotionData).length > 0;
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };


    const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
    const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
    const restaurant_Id = globalAuthString.restaurant_Id?globalAuthString.restaurant_Id:null;
    const restId = restaurant_Id ? restaurant_Id : (localAuthString ? localAuthString.restaurant_Id : null);

    useEffect(() => {
       
            axios.get(`/promotions/restaurant/${restId}`)
                .then(response => {
                    console.log("response.data", response.data);
                    const data = response.data;
                    setpromotionData(data);
                })
                .catch(error => {
                    console.error('Error fetching record:', error);
                });
        
    }, [restId]);

    useEffect(() => {
        console.log("Data promotions:", promotionData);

    }, [promotionData]);


    useEffect(() => {

            axios.get(`/food-items/restaurant/${restId}`)
                .then(response => {
                    console.log("response.data", response.data);
                    const data = response.data.data;
                    setFoodItemsData(data);
                })
                .catch(error => {
                    console.error('Error fetching record:', error);
                });
        
    }, [restId]);

    useEffect(() => {
        console.log("Data items:", foodItemsData);

    }, [foodItemsData]);



    const addRecord = async (values) => {

        try {
            console.log("Making a POST request to fooditems with data:", values);

            const response = await axios.post('/product_promotions', values, config);

            console.log("Response status:", response.status);
            console.log("Response data:", response.data);
            alert("Added Successfully!");

        } catch (error) {
            console.error("Error while making the POST request:", error.message);
            alert("Error Adding Record");
        }
    };



    const formik = useFormik({
        initialValues: {
            promotion_id: null,
            item_id: null,
            restaurant_Id: restId ? restId : '',



        },

        validationSchema: Yup.object({

            promotion_id: Yup.number().required('This field is required'),
            item_id: Yup.number().required('This field is required'),

        }),
        onSubmit: (values, { resetForm }) => {
            // Handle form submission logic, e.g., send data to the 
            console.log(values);
            addRecord(values);

            navigate("/active-promotions");
        },
    });
    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                <FormikProvider value={formik}>
                    <button type="button" className="btn btn-primary mt-3 ms-5" data-mdb-ripple-init="true">
                        <Link to="/active-promotions" className="text-white text-decoration-none">Active Promotions List</Link>
                    </button>
                    < MDBCard className='p-5 mt-3'>
                        <form onSubmit={formik.handleSubmit}>
                            <MDBRow className='mb-4'>
                                <MDBCol>
                                    <Form.Group controlId="promotion_id">
                                        <label className="form-label" htmlFor="promotion_id">Promotions</label>
                                        <Field
                                            as="select"
                                            className="browser-default custom-select"
                                            id="promotion_id"
                                            name="promotion_id"
                                            value={formik.values.promotion_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.promotion_id && formik.errors.promotion_id}
                                        >
                                            <option value="" disabled selected>Select Promotions</option>
                                            {promotionData?.map(category => (
                                                <option key={category.id} value={category.id}>{category.promotion_name}</option>
                                            ))}

                                        </Field>
                                        <small><ErrorMessage name="promotion_id" component="div" className="text-danger" /></small>
                                    </Form.Group>

                                </MDBCol>
                                <MDBCol>
                                    <Form.Group controlId="item_id">
                                        <label className="form-label" htmlFor="item_id">Food Items</label>
                                        <Field
                                            as="select"
                                            className="browser-default custom-select"
                                            id="item_id"
                                            name="item_id"
                                            value={formik.values.item_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.item_id && formik.errors.item_id}
                                        >
                                            <option value="" disabled selected>Select Food Item</option>
                                            {foodItemsData?.map(category => (
                                                <option key={category.id} value={category.id}>{category.title}</option>
                                            ))}

                                        </Field>
                                        <small><ErrorMessage name="item_id" component="div" className="text-danger" /></small>
                                    </Form.Group>

                                </MDBCol>
                            </MDBRow>


                            <button className='mb-4 mt-5 btn btn-primary w-100' type='submit' >
                                Add
                            </button>
                        </form>
                    </MDBCard>
                </FormikProvider>
            </DashboardLayout>
        </>
    );
}