
import React, {useState, useEffect} from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import "./index.css";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from "primereact/api";
import { AuthProvider} from './context/AuthContext';
// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";


const container = document.getElementById("app");
const root = createRoot(container);


root.render(
  <BrowserRouter>
       <AuthProvider>
    <MaterialUIControllerProvider>
      
      <App />
    </MaterialUIControllerProvider>
    </AuthProvider>
  </BrowserRouter>
);
