import { useState } from "react";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
    MDBRow,
    MDBCol,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBCard,
    MDBFile
  } from 'mdb-react-ui-kit';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import Card from "@mui/material/Card";
// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import backgroundImage from "assets/images/bg-profile.jpeg";
// Data
import profilesListData from "layouts/profile/data/profilesListData";


function Shop() {
    const [cities, setCities] = useState([{ city: '', area: '' }]);

  const handleAddMore = () => {
    setCities([...cities, { city: '', area: '' }]);
  };

  const handleEdit = (index) => {
    // Define edit functionality here
  };

  const handleDelete = (index) => {
    setCities(cities.filter((_, i) => i !== index));
  };

  const handleCityChange = (index, value) => {
    const updatedCities = [...cities];
    updatedCities[index].city = value;
    setCities(updatedCities);
  };

  const handleAreaChange = (index, value) => {
    const updatedCities = [...cities];
    updatedCities[index].area = value;
    setCities(updatedCities);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox position="relative" mb={5}>
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
        <MDBox mt={5} mb={3} className="p-2">
 
            {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
      
            
              <Card  className="p-5"      sx={{
          position: "relative",
          mt: -15,
          mx: 3,
          py: 2,
          px: 2,
        }}>  
               <form>
               <MDBRow className='mb-4'>
                 <MDBCol>
                 <label className="form-label" >Company Code</label>
                   <MDBInput id='form6Example1' />
                 </MDBCol>
                 <MDBCol>
                 <label className="form-label" >Company Name</label>
                   <MDBInput id='form6Example2'  />
                 </MDBCol>
               </MDBRow>
               <MDBRow className='mb-4'>
                 <MDBCol>
                 <label className="form-label" >Contact Phone Number</label>
                   <MDBInput id='form6Example1'  />
                 </MDBCol>
                 <MDBCol>
                 <label className="form-label" >Contact Email</label>
                   <MDBInput id='form6Example2'  />
                 </MDBCol>
               </MDBRow>
               <MDBRow className='mb-4'>
                 <MDBCol>
                 <label className="form-label" >Facebook URL</label>
                 <MDBInput wrapperClass='mb-4' type='email' id='form6Example5'  />
                 </MDBCol>
                 <MDBCol>
                 <label className="form-label" >Instagram URL</label>
                 <MDBInput wrapperClass='mb-4' type='tel' id='form6Example6'  />
                 </MDBCol>
               </MDBRow>
             
               <MDBRow className='mb-4'>
      
        <MDBCol>
        <label className="form-label" >Logo</label>
        <input type="file" className="form-control" id="customFile" />
     
        </MDBCol>
      </MDBRow>  <MDBRow className='mb-4'>
                 <MDBCol>
                 <label className="form-label" >Description</label>
                 <textarea className="browser-default custom-select" rows={5} id="package"> 
          
         </textarea> 
       
                 </MDBCol>
               </MDBRow>
         
               {cities.map((city, index) => (
          <MDBRow key={index} className="mb-4">
            <MDBCol md={5}>
            <label className="form-label" >City</label>
              <MDBInput
                value={city.city}
                onChange={(e) => handleCityChange(index, e.target.value)}
               
              />
            </MDBCol>
            <MDBCol md={5}>
            <label className="form-label" >Area</label>
              <MDBInput
                value={city.area}
                onChange={(e) => handleAreaChange(index, e.target.value)}
                
              />
            </MDBCol>
            <MDBCol >
              {/* Edit and Delete buttons */}
              <MDBBtn color="warning" size="sm" onClick={() => handleEdit(index)}>
                Edit
              </MDBBtn>{"   "}
              <MDBBtn color="danger" size="sm" onClick={() => handleDelete(index)}>
                Delete
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        ))}
        {/* Add More button */}
        <MDBRow className="mb-4">
          <MDBCol>
            <MDBBtn color="primary" size="sm" onClick={handleAddMore}>
              Add More
            </MDBBtn>
          </MDBCol>
        </MDBRow>
         
               <MDBBtn className='mb-4' type='submit' block>
                 Create
               </MDBBtn>
             </form>
              </Card>
              
         
           
     
        </MDBox>
        </MDBox>


    </DashboardLayout>
  );
}

export default Shop;
