import React, { useState, useEffect } from 'react';
import useAxiosPrivate from "../../../../hook/useAxiosPrivate";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
  MDBTypography,
  MDBTextArea,
  MDBCardHeader,
  MDBInputGroup,
} from "mdb-react-ui-kit";
import { Form } from 'react-bootstrap';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useAuth } from '../../../../context/AuthContext';

export default function App() {
  const [selectedUser, setSelectedUser] = useState('');
  const [messageContent, setMessageContent] = useState('');
  const [users, setUsers] = useState([]);
  const axios = useAxiosPrivate();
  const { auth } = useAuth();
  const [recentSenders, setRecentSenders] = useState([]);
  const [recentChats, setRecentChats] = useState([]);
  const [selectedSenderId, setSelectedSenderId] = useState(null);



  const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
  const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
  const user_Id = globalAuthString.emp_id ? globalAuthString.emp_id : null;
  const isSuperAdmin = globalAuthString.isSuperAdmin ? globalAuthString.isSuperAdmin : null;
  const userId = localAuthString ? localAuthString.emp_id : (user_Id ? user_Id : null);



  const fetchRecentChats = async () => {
    try {
      const response = await axios.get(`/messages/chat-history/${selectedSenderId}/${userId}`);
      setRecentChats(response.data.data);
    } catch (error) {
      console.error('Error fetching recent senders:', error);
    }
  };
  useEffect(() => {
    if (selectedSenderId && userId) {
      fetchRecentChats();
    }
  }, [selectedSenderId, userId]);


  useEffect(() => {
    console.log("isSuperAdmin", isSuperAdmin);
    
    const fetchRecentSenders = async () => {
      try {
        const response = await axios.get(`/messages/recent-senders/${userId}`);
        const senders = response.data.data;
        console.log("response.data",response.data.data);
        
        // Filter out the sender whose SenderID is the same as userId
        const filteredSenders = senders.filter(sender => sender.id !== userId);
        
        setRecentSenders(filteredSenders);
      } catch (error) {
        console.error('Error fetching recent senders:', error);
      }
    };
  
    fetchRecentSenders();
  }, [userId]);
  


  // Fetch users data
  useEffect(() => {
    // Simulated API call to fetch users data
    // Replace this with your actual API call
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/users');
        console.log(response.data.data);
        const filteredUsers = response.data.data.filter(user => user.roleId === 2);
        setUsers(filteredUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessageContent(e.target.value);
  };

  const sendMessage = async () => {
    try {
      const response = await axios.post('/messages', {
        SenderID: userId, // Assuming selectedUser is the ID of the sender
        ReceiverID: selectedUser, // Assuming receiverID is the ID of the receiver
        Content: messageContent
      });

      console.log('Message sent successfully:', response.data);
      alert('Message sent successfully');
      // Optionally, you can update the UI or perform any other action upon successful message sending
    } catch (error) {
      console.error('Error sending message:', error);
      // Optionally, handle the error and update the UI accordingly
    }
  };


  const replyMessage = async () => {
    try {
      if (!userId || !selectedSenderId) {
        return alert("Cannot send, sender & Receiver Id required")

      }
      const response = await axios.post('/messages', {
        SenderID: userId, // Assuming selectedUser is the ID of the sender
        ReceiverID: selectedSenderId, // Assuming receiverID is the ID of the receiver
        Content: messageContent
      });

      console.log('Message sent successfully:', response.data);
      alert('Message sent successfully');
      fetchRecentChats();
      // Optionally, you can update the UI or perform any other action upon successful message sending
    } catch (error) {
      console.error('Error sending message:', error);
      // Optionally, handle the error and update the UI accordingly
    }
  };
  const formatDate = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    const date = dateTime.toLocaleDateString();
    const time = dateTime.toLocaleTimeString();
    return `${date} ${time}`;
  };


  const [searchQuery, setSearchQuery] = useState(""); // State to hold the search query


  // Update filtered senders whenever the search query changes
  const [filteredSenders, setFilteredSenders] = useState(recentSenders);
  useEffect(() => {
    const lowerCasedQuery = searchQuery.toLowerCase();
    setFilteredSenders(
      recentSenders.filter(sender => 
        sender.name && sender.name.toLowerCase().includes(lowerCasedQuery)
      )
    );
  }, [searchQuery, recentSenders]);

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value); // Update search query as user types
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isSuperAdmin && (!localAuthString || Object.keys(localAuthString).length === 0) &&
        <MDBRow className=" px-5">
          <MDBCol md="11" lg="11" xl="11" className="mb-4 mb-md-0 mt-5">
            <MDBTypography listUnStyled>
              <Form.Select value={selectedUser} onChange={handleUserChange} className='form-control mb-3'>
                <option value="" disabled selected>Select User</option>
                {users.map(user => (
                  <option key={user.id} value={user.id}>{user.firstName}</option>
                ))}
              </Form.Select>
              <li className="bg-white mb-3">
                <MDBTextArea
                  label="Message"
                  id="textAreaExample"
                  rows={4}
                  value={messageContent}
                  onChange={handleMessageChange}

                />
              </li>
              <button className=" btn-primary float-end px-4" onClick={sendMessage}>
                Send
              </button>
            </MDBTypography>
          </MDBCol>
        </MDBRow>

      }
      {/* <MDBRow className=" px-5">


        <MDBCol md="6" lg="5" xl="4" className="mb-4 mb-md-0 ms-5">
          <h5 className="font-weight-bold mb-3 text-center text-lg-start">
            Recent Chats
          </h5>

          <MDBCard>
            <MDBCardBody>
              <MDBTypography listUnStyled className="mb-0">
                <li className="p-2 border-bottom" style={{ backgroundColor: "#eee" }}>
                  <MDBInputGroup className="rounded mb-3">
                    <input
                      className="form-control rounded"
                      placeholder="Search"
                      type="search"
                    />
                    <span className="input-group-text border-0" id="search-addon">
                      <MDBIcon fas icon="search" />
                    </span>
                  </MDBInputGroup>
                </li>

                {recentSenders.map((sender) => (
                  <li key={sender.SenderID} className="p-2">
                    <a href="#!" className="d-flex justify-content-between">
                      <div className="d-flex flex-row">
                        <div className="pt-1">
                          // <p className="fw-bold mb-0" onClick={() => setSelectedSenderId(sender.SenderID)}>{sender.user_messages_SenderIDTouser.firstName}</p> 
                          <p className="fw-bold mb-0" onClick={() => {
                            if (sender.SenderID === userId && globalAuthString.userRole==="Super Admin") {
                              setSelectedSenderId(sender.ReceiverID)
                            }
                            else {
                              setSelectedSenderId(sender.SenderID)
                            }
                          }}>{(sender.SenderID && globalAuthString.userRole==="Super Admin") === userId ? sender.user_messages_ReceiverIDTouser.firstName : sender.user_messages_SenderIDTouser.firstName}</p>

                          <p className="small text-muted">
                            
                          </p>
                        </div>
                      </div>
                      <div className="pt-1">
                  
                      </div>
                    </a>
                  </li>
                ))}
              </MDBTypography>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>


        <MDBCol md="6" lg="7" xl="7" className="mb-4 mb-md-0 mt-5">
          <ul className="list-unstyled">
            {recentChats.map((message, index) => (
              <li key={index} className={`d-flex justify-content-start mb-4`}>
                <MDBCard className='w-100'>
                  <MDBCardHeader className="d-flex justify-content-between  p-3">
                    <p className="fw-bold mb-0 ">{message.SenderID === userId ? 'You' : 'Other User'}</p>
                    <p className="text-muted small  mb-0">{formatDate(message.created_at)}</p>
                  </MDBCardHeader>
                  <MDBCardBody>
                    <p className="mb-0">{message.Content}</p>
                  </MDBCardBody>
                </MDBCard>
              </li>
            ))}
            <li className="bg-white mb-3">
              <MDBTextArea
                label="Reply"
                id="textAreaExample"
                rows={4}
                value={messageContent}
                onChange={handleMessageChange}

              />
            </li>
            <button className=" btn-primary float-end px-4" onClick={replyMessage}>
              Send
            </button>
          </ul>

        </MDBCol>
      </MDBRow> */}
     
      <MDBRow className=" px-5">
      <MDBCol md="6" lg="5" xl="4" className="mb-4 mb-md-0 ms-5">
        <h5 className="font-weight-bold mb-3 text-center text-lg-start">
          Recent Chats
        </h5>

        <MDBCard>
          <MDBCardBody>
            <MDBTypography listUnStyled className="mb-0">
              {/* Search Box */}
              <li className="p-2 border-bottom" style={{ backgroundColor: "#eee" }}>
                <MDBInputGroup className="rounded mb-3">
                  <input
                    className="form-control rounded"
                    placeholder="Search"
                    type="search"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <span className="input-group-text border-0" id="search-addon">
                    <MDBIcon fas icon="search" />
                  </span>
                </MDBInputGroup>
              </li>

              {/* Display Filtered Senders */}
              {filteredSenders.map((sender) => (
                <li key={sender.SenderID} className="p-2">
                  <a href="#!" className="d-flex justify-content-between">
                    <div className="d-flex flex-row">
                      <div className="pt-1">
                      <p className="fw-bold mb-0" onClick={() => setSelectedSenderId(sender.id)}>{sender.name}</p>
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </MDBTypography>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

      {/* Chat History and Reply Block */}
      <MDBCol md="6" lg="7" xl="7" className="mb-4 mb-md-0 mt-5">
        <ul className="list-unstyled">
          {recentChats.map((message, index) => (
            <li key={index} className={`d-flex justify-content-start mb-4`}>
              <MDBCard className='w-100'>
                <MDBCardHeader className="d-flex justify-content-between  p-3">
                  <p className="fw-bold mb-0 ">{message.SenderID === userId ? 'You' : 'Other User'}</p>
                  <p className="text-muted small  mb-0">{formatDate(message.created_at)}</p>
                </MDBCardHeader>
                <MDBCardBody>
                  <p className="mb-0">{message.Content}</p>
                </MDBCardBody>
              </MDBCard>
            </li>
          ))}
          <li className="bg-white mb-3">
            <MDBTextArea
              label="Reply"
              id="textAreaExample"
              rows={4}
              value={messageContent}
              onChange={handleMessageChange}
            />
          </li>
          <button className=" btn-primary float-end px-4" onClick={replyMessage}>
            Send
          </button>
        </ul>
      </MDBCol>
    </MDBRow>


    </DashboardLayout>
  );
}