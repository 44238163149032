/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import SuperAdminDashboard from "layouts/SuperAdmindashboard";
import Tables from "layouts/tables";
import KitchenAdmins from "layouts/KitchenAdmins";
import FoodCategories from "layouts/FoodCategories";
import FoodItems from "layouts/FoodItems";
import Orders from "layouts/Orders/Orders";
import Reports from "layouts/Reports";
import AddFoodItem from "layouts/FoodItems/AddItem";

import AddArea from "layouts/Areas/AddArea";
import AddCity from "layouts/Cities/AddCity";
import Area from "layouts/Areas/";
import City from "layouts/Cities/";


import AddPromotion from "layouts/Promotions/AddPromotion";
import AddPackage from "layouts/Packages/AddPackage";
import Promotions from "layouts/Promotions/";
import Packages from "layouts/Packages/";
import ActivePromotions from "layouts/ActivePromotions/";
import ActivatePromotion from "layouts/ActivePromotions/ActivatePromotion";

import AddRestaurant from "layouts/Resturants/AddRestaurant";
import Restaurant from "layouts/Resturants/";
import AddExtras from "layouts/Extras/AddValue";
import Extras from "layouts/Extras";
import AddExtrasCategory from "layouts/Extras/Categories/AddCategory";
import ExtrasCategories from "layouts/Extras/Categories";
import AddCategory from "layouts/FoodCategories/AddCategory";
import AddAdmin from "layouts/KitchenAdmins/AddAdmin";
import EditProfile from "layouts/KitchenAdmins/EditProfile";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import Chat from "layouts/profile/components/Chat/Chat";
import SignIn from "layouts/authentication/sign-in";
import Shop from "layouts/Shop";
import SignUp from "layouts/authentication/sign-up";
import Employees from "layouts/Employees";
import AddEmployee from "layouts/Employees/AddEmployee.js";
import Customers from "layouts/Customers";
import UpdateCustomer from "layouts/Customers/UpdateCustomer.js";
import Recipients from "layouts/Recipient";
import Restaurants from "layouts/Resturants";
// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "City List",
    key: "city",
    icon: <Icon fontSize="small">location_city</Icon>,
    route: "/cities",
    component: <City/>,
  },
  {
    type: "",
    name: "Area List",
    key: "area",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/areas",
    component: <Area/>,
  },
  {
    type: "collapse",
    name: "Add Area",
    key: "area",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/add-area",
    component: <AddArea/>,
  },
  {
    type: "collapse",
    name: "Add City",
    key: "city",
    icon: <Icon fontSize="small">location_city</Icon>,
    route: "/add-city",
    component: <AddCity/>,
  },
  {
    type: "collapse",
    name: "AdminDashboard",
    key: "kitchen-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/kitchen-admin-dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "SuperDashboard",
    key: "super-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/super-admin-dashboard",
    component: <SuperAdminDashboard />,
  },

  {
    type: "collapse",
    name: "Kitchen Admins",
    key: "kitchenAdmin",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/kitchen-admins",
    component: <KitchenAdmins />,
  },
  {
    type: "",
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "",
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "",
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    type: "",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/",
    component: <SignIn />,
  },
  {
    type: "",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
   {
    type: "collapse",
    name: "Add Kitchen Admin",
    key: "Add Kitchen Admin",
    icon: <Icon fontSize="small">person_add</Icon>,
    route: "/add-admin",
    component: <AddAdmin/>,
  },
  {
    type: "collapse",
    name: "Add Food Category",
    key: "Add Food Category",
    icon: <Icon fontSize="small">add_circle</Icon>,
    route: "/add-category",
    component: <AddCategory/>,
  },
  {
    type: "collapse",
    name: "Food Categories",
    key: "Food Categories",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/food-categories",
    component: <FoodCategories/>,
  },
  {
    type: "collapse",
    name: "Food Items",
    key: "Food Items",
    icon: <Icon fontSize="small">restaurant_menu</Icon>,
    route: "/restaurant-food-items",
    component: <FoodItems/>,
  },
  {
    type: "collapse",
    name: "Add Food Item",
    key: "Add Food Item",
    icon: <Icon fontSize="small">add_box</Icon>,
    route: "/add-food-item",
    component: <AddFoodItem/>,
  },
  {
    type:"collapse",
    name: "Messages",
    key: "Chat",
    icon: <Icon fontSize="small">chat</Icon>,
    route: "/chat",
    component: <Chat/>,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "Orders",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/orders",
    component: <Orders/>,
  },
  {
    type: "collapse",
    name: "Edit Profile",
    key: "editprofile",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/edit-profile",
    component: <EditProfile/>,
  },
  {
    type: "collapse",
    name: "Shop",
    key: "shop",
    icon: <Icon fontSize="small">storefront</Icon>,
    route: "/shop",
    component: <Shop/>,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/restaurant-customers",
    component: <Customers/>,
  },
  {
    type: "collapse",
    name: "Employees",
    key: "employees",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/employees",
    component: <Employees/>,
  },
  {
    type: "",
    name: "Add Employee",
    key: "addemployees",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/add-employee",
    component: <AddEmployee/>,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "addemployees",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/add-employee",
    component: <AddEmployee/>,
  },
  {
    type: "collapse",
    name: "Extras Categories",
    key: "extrasCategories",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/extras-categories",
    component: <ExtrasCategories/>,
  },
  {
    type: "collapse",
    name: "Add Category",
    key: "addExtrasCategories",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/add-extras-category",
    component: <AddExtrasCategory/>,
  },
  {
    type: "collapse",
    name: "Extras",
    key: "food-items-extras",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/food-items-extras",
    component: <Extras/>,
  },
  {
    type: "collapse",
    name: "Add Extras",
    key: "addExtras",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/add-extras",
    component: <AddExtras/>,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">analytics</Icon>,
    route: "/reports",
    component: <Reports/>,
  },
  {
    type: "collapse",
    name: "Add Restaurant",
    key: "add-restaurant",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "add-restaurant",
    component: <AddRestaurant/>,
  },
  {
    type: "collapse",
    name: "Restaurant List",
    key: "restaurant",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/registered-restaurants",
    component: <Restaurant/>,
  },
  {
    type: "collapse",
    name: "Add Area",
    key: "area",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/add-area",
    component: <AddArea/>,
  },
  {
    type: "collapse",
    name: "Add City",
    key: "city",
    icon: <Icon fontSize="small">location_city</Icon>,
    route: "/add-city",
    component: <AddCity/>,
  },
  {
    type: "collapse",
    name: "Packages",
    key: "packages",
    icon: <Icon fontSize="small">monetization_on</Icon>,
    route: "/packages",
    component: <Packages/>,
  },
  {
    type: "",
    name: "Add Package",
    key: "add-package",
    icon: <Icon fontSize="small">monetization_on</Icon>,
    route: "/add-package",
    component: <AddPackage/>,
  },
  {
    type: "collapse",
    name: "Promotions",
    key: "promotions",
    icon: <Icon fontSize="small">local_offer</Icon>,
    route: "/all-promotions",
    component: <Promotions/>,
  },
  {
    type: "collapse",
    name: "add-promotions",
    key: "add-promotion",
    icon: <Icon fontSize="small">local_offer</Icon>,
    route: "/add-promotion",
    component: <AddPromotion/>,
  },
  {
    type: "",
    name: "activate-promotion",
    key: "activate-promotion",
    icon: <Icon fontSize="small">local_offer</Icon>,
    route: "/activate-promotion",
    component: <ActivatePromotion/>
  },
  {
    type: "collapse",
    name: "active-promotions",
    key: "active-promotions",
    icon: <Icon fontSize="small">local_offer</Icon>,
    route: "/active-promotions",
    component: <ActivePromotions/>
  },
  {
    type: "collapse",
    name: "Recipients",
    key: "recipients",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/customer-recipients",
    component: <Recipients/>
  },
  {
    type: "",
    name: "Update customer",
    key: "customer",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/update-customer",
    component: <UpdateCustomer/>
  },
];


export const superAdminRoutes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "super-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/super-admin-dashboard",
    component: <SuperAdminDashboard />,
  },
  {
    type: "",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },

  {
    type:"collapse",
    name: "Restaurants",
    key: "Restaurants",
    icon: <Icon fontSize="small">storefront</Icon>,
    route: "/registered-restaurants",
    component: <Restaurants/>,
  },
  {
    type: "collapse",
    name: "Kitchen Admins",
    key: "kitchenAdmin",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/kitchen-admins",
    component: <KitchenAdmins />,
  },
  {
    type:"collapse",
    name: "Messages",
    key: "Chat",
    icon: <Icon fontSize="small">chat</Icon>,
    route: "/chat",
    component: <Chat/>,
  },
  
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/restaurant-customers",
    component: <Customers superAdmin={true}/>,
  },
  {
    type: "collapse",
    name: "Recipients",
    key: "recipients",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/customer-recipients",
    component: <Recipients/>
  },
  {
    type: "collapse",
    name: "City List",
    key: "city",
    icon: <Icon fontSize="small">location_city</Icon>,
    route: "/cities",
    component: <City/>,
  },
  {
    type: "collapse",
    name: "Area List",
    key: "area",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/areas",
    component: <Area/>,
  },

  {
    type: "collapse",
    name: "Packages",
    key: "packages",
    icon: <Icon fontSize="small">monetization_on</Icon>,
    route: "/packages",
    component: <Packages/>,
  },
  {
    type: "",
    name: "Add Package",
    key: "add-package",
    icon: <Icon fontSize="small">monetization_on</Icon>,
    route: "/add-package",
    component: <AddPackage/>,
  },

];
export const kitchenAdminRoutes = [

  {
    type: "collapse",
    name: "Dashboard",
    key: "kitchen-dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/kitchen-admin-dashboard",
    component: <Dashboard />,
  },
  // {
  //   type: "collapse",
  //   name: "Edit Profile",
  //   key: "editprofile",
  //   icon: <Icon fontSize="small">edit</Icon>,
  //   route: "/edit-profile",
  //   component: <EditProfile/>,
  // },
  {
    type: "collapse",
    name: "Restaurant",
    key: "add-restaurant",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "add-restaurant",
    component: <AddRestaurant/>,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/add-admin",
    component: <AddAdmin/>,
    },
  
  {
    type: "collapse",
    name: "Food Categories",
    key: "Food Categories",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/food-categories",
    component: <FoodCategories/>,
  },
  {
    type: "collapse",
    name: "Food Items",
    key: "Food Items",
    icon: <Icon fontSize="small">restaurant_menu</Icon>,
    route: "/restaurant-food-items",
    component: <FoodItems/>,
  },

  {
    type:"collapse",
    name: "Admin Messages",
    key: "Chat",
    icon: <Icon fontSize="small">chat</Icon>,
    route: "/chat",
    component: <Chat/>,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "Orders",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/orders",
    component: <Orders/>,
  },
 

  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/restaurant-customers",
    component: <Customers/>,
  },
  {
    type: "collapse",
    name: "Employees",
    key: "employees",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/employees",
    component: <Employees/>,
  },


  {
    type: "collapse",
    name: "Promotions",
    key: "promotions",
    icon: <Icon fontSize="small">local_offer</Icon>,
    route: "/all-promotions",
    component: <Promotions/>,
  },


  {
    type: "collapse",
    name: "Active Promotions",
    key: "active-promotions",
    icon: <Icon fontSize="small">local_offer</Icon>,
    route: "/active-promotions",
    component: <ActivePromotions/>
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">analytics</Icon>,
    route: "/reports",
    component: <Reports/>,
  },
 

  {
    type: "collapse",
    name: "City List",
    key: "city",
    icon: <Icon fontSize="small">location_city</Icon>,
    route: "/cities",
    component: <City/>,
  },
  {
    type: "collapse",
    name: "Area List",
    key: "area",
    icon: <Icon fontSize="small">location_on</Icon>,
    route: "/areas",
    component: <Area/>,
  },

];
export default routes;
