import React, { useEffect, useState, useRef } from 'react'
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import DataTable from "examples/Tables/DataTable";
import DataTable from "examples/PrimeReactDatatable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
// Images
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { useMaterialUIController } from "context";
import { Link, useNavigate } from 'react-router-dom';
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import { useAuth } from '../../context/AuthContext';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

Orders.propTypes = {
  report: PropTypes.bool.isRequired,
};

const statusMapping = {
  0: 'Locked',
  1: 'Pending',
  2: 'Processing',
  3: 'Shipped',
  4: 'Delivered'
};

const reverseStatusMapping = {
  'Locked': 0,
  'Pending': 1,
  'Processing': 2,
  'Shipped': 3,
  'Delivered': 4
};
function Orders({ report }) {



  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { auth } = useAuth();
  const [apiResponse, setApiResponse] = useState([]);
  const prevApiResponse = useRef(null);
  const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
  const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
  const restaurant_Id = globalAuthString.restaurant_Id ? globalAuthString.restaurant_Id : null;
  const restId = restaurant_Id ? restaurant_Id : (localAuthString ? localAuthString.restaurant_Id : null);
  const fetchOrder = async () => {
    try {
      const response = await axios.get(`/order/restaurant/${restId}`);
      const data = response.data.data;
      console.log("fetch response", data);
      setApiResponse(data);
    } catch (error) {
      console.error('Error fetching Orders:', error);
    }
  };
  useEffect(() => {

    fetchOrder();
  }, []);

  useEffect(() => {
    console.log("Api response", apiResponse)
  }, [apiResponse]);



  const handleStatusChange = async (id, newStatus) => {
    const newStatusValue = reverseStatusMapping[newStatus];

    try {
      const response = await axios.put(`/api/orders/${id}/status`, { status: newStatusValue });
      console.log('Order updated:', response.data);
    } catch (error) {
      console.error('Failed to update order status:', error);
    }
  }



  const handleDeleteUser = (taskId) => {
    axios.delete(`/Order/${taskId}`)
      .then(response => {
        console.log(response.data);
        // Refresh the task list after deletion
        fetchOrder();
        alert("Record deleted successfully!");

      })
      .catch(error => {
        console.error('Error deleting category:', error);
        alert("Error deleting record");

      });

  };
  // const handleEditItem = (taskId) => {

  //   navigate(`/add-admin?taskId=${taskId}`);
  // };

  const [tableData, setTableData] = useState({
    columns: [
      { field: 'id', header: 'Id', sortable: true },
      { field: 'customerId', header: 'Customer Id', sortable: true },
      { field: 'order_number', header: 'Order Number', sortable: true },
      { field: 'order_items', header: 'Order Items', sortable: true },
      { field: 'date', header: 'Created On', sortable: true, dataType: "date" },
      { field: 'total', header: 'Total', sortable: true },
      { field: 'status', header: 'Status', sortable: true },
      { field: 'action', header: 'Action', sortable: false },

    ],
    items: [

      // {id: 4, name: 'Pizzas', status: 1, description: 'Pizzas Category', created_on: '2024-04-22T17:37:57.000Z'},
      // {id: 5, name: 'Sandwich', status: 1, description: 'Sandwich', created_on: '2024-04-22T18:29:13.000Z'}

    ],
    paginator: true,
    rows: 10,
    resizableColumns: true,
    showGridlines: true,
    loading: false,
    emptyMessage: "No data found."
  });

  const formattedDate = (date) => {
    const d = new Date(date);
    if (isNaN(d.getTime())) {
      // If the date is invalid, return 'Invalid Date'
      console.error('Invalid date:', date);
      return 'Invalid Date';
    } else {
      // Format the date as needed
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const day = ('0' + d.getDate()).slice(-2);
      const year = d.getFullYear();
      return [year, month, day].join('-');
    }
  }

  useEffect(() => {
    console.log("response:", apiResponse); // Log updated apiResponse
    if (apiResponse !== prevApiResponse.current) {
      // Update prevApiResponse ref
      prevApiResponse.current = apiResponse;
      // Update rows based on apiResponse
      const updatedRows = apiResponse?.map((item, index) => ({
        id: item.id,
        customerId: item.customerId,
        order_number: item.order_number,
        order_items: item.order_item.map(orderItem => (
          <div key={orderItem.id}>
            {/* Format order item details as needed */}
            <p>{`Item ID: ${orderItem.itemId}, Quantity: ${orderItem.quantity}`}</p>
            <p>{`Name: ${orderItem.itemName}, Variation: ${orderItem.variationName || ""}`}</p>
            <p>{`Extras: ${orderItem.extras || "None"}`}</p>
          </div>
        )),
        date: formattedDate(item.createdAt),

        total: item.total,
        status: (
          <select
            value={statusMapping[item.status]}
            onChange={(e) => handleStatusChange(item.id, e.target.value)}
            className='bg-primary'
            style={{ color: 'white', backgroundColor: 'lightgray', border: 'none', padding: '5px 25px', borderRadius: '20px' }}
          >
            <option value="Locked" style={{ color: 'red' }}>Locked</option>
            <option value="Pending" style={{ color: 'green' }}>Pending</option>
            <option value="Processing" style={{ color: 'purple' }}>Processing</option>
            <option value="Shipped" style={{ color: 'orange' }}>Shipped</option>
            <option value="Delivered" style={{ color: 'yellow' }}>Delivered</option>
          </select>
        ),
        action: (
          <MDButton variant="text" color="error" onClick={() => handleDeleteUser(item.id)}>
            <Icon>delete</Icon>&nbsp;delete
          </MDButton>
        )
      }));

      console.log("updatedRows", updatedRows);
      // Update tableData with new rows
      setTableData(prevTableData => ({
        ...prevTableData,
        items: updatedRows,
      }));
      console.log(tableData);
    }
  },);

  return (
    report ? (
      <DataTable
        tableData={tableData}
      />
    ) : (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Recent Orders
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    tableData={tableData}
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
    )
  );
}

export default Orders;
