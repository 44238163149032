/**
=========================================================
* Material Dashboard 2  React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ReportsBarChart configurations
import configs from "examples/Charts/BarCharts/ReportsBarChart/configs";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function ReportsBarChart({ color, title, description, date, chart, ReportChart }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  const chartComponent = useMemo(() => (
    <MDBox
      variant="gradient"
      bgColor={color}
      borderRadius="lg"
      coloredShadow={color}
      py={2}
      pr={0.5}
      mt={ReportChart ? 1 : -5} // Adjusted based on ReportChart
      height={ReportChart ? "25.5rem" : "12.5rem"} // Adjusted based on ReportChart
    >
      <Bar data={data} options={options} redraw />
    </MDBox>
  ), [color, chart, data, options, ReportChart]); // Added all dependencies

  return (
    <Card sx={{ height: "100%" }}>
    {ReportChart ? (
      <>
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography component="div" variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
        </MDBox>
        <MDBox padding="1rem">
          {chartComponent}
        </MDBox>
      </>
    ) : (
      <MDBox padding="1rem">
        {chartComponent}
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography component="div" variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {date}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    )}
    </Card>

  //   <Card sx={{ height: "100%" }}>
  //        {ReportChart ? (
  //       <>
  //         <MDBox pt={3} pb={1} px={1}>
  //           <MDTypography variant="h6" textTransform="capitalize">
  //             {title}
  //           </MDTypography>
  //           <MDTypography component="div" variant="button" color="text" fontWeight="light">
  //             {description}
  //           </MDTypography>
  //         </MDBox>
  //         <MDBox padding="1rem">
  //           {useMemo(
  //             () => (
  //               <MDBox
  //                 variant="gradient"
  //                 bgColor={color}
  //                 borderRadius="lg"
  //                 coloredShadow={color}
  //                 py={2}
  //                 pr={0.5}
  //                 mt={1}
  //                 height="25.5rem"
  //               >
  //                 <Bar data={data} options={options} redraw />
  //               </MDBox>
  //             ),
  //             [color, chart]
  //           )}
  //         </MDBox>
  //       </>
  //     ) : (
  //       <MDBox padding="1rem">
  //         {useMemo(
  //           () => (
  //             <MDBox
  //               variant="gradient"
  //               bgColor={color}
  //               borderRadius="lg"
  //               coloredShadow={color}
  //               py={2}
  //               pr={0.5}
  //               mt={-5}
  //               height="12.5rem"
  //             >
  //               <Bar data={data} options={options} redraw />
  //             </MDBox>
  //           ),
  //           [color, chart]
  //         )}
  //         <MDBox pt={3} pb={1} px={1}>
  //           <MDTypography variant="h6" textTransform="capitalize">
  //             {title}
  //           </MDTypography>
  //           <MDTypography component="div" variant="button" color="text" fontWeight="light">
  //             {description}
  //           </MDTypography>
  //           <Divider />
  //           <MDBox display="flex" alignItems="center">
  //             <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
  //               <Icon>schedule</Icon>
  //             </MDTypography>
  //             <MDTypography variant="button" color="text" fontWeight="light">
  //               {date}
  //             </MDTypography>
  //           </MDBox>
  //         </MDBox>
  //       </MDBox>
  //     )}
  //   </Card>
   );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the ReportsBarChart
ReportsBarChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  ReportChart: PropTypes.bool.isRequired,
};

export default ReportsBarChart;
