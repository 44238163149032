import React, { useEffect, useState, useRef } from 'react'
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
//import DataTable from "examples/Tables/DataTable";
import DataTable from "examples/PrimeReactDatatable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

import { useMaterialUIController } from "context";
import { Link, useNavigate } from 'react-router-dom';
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import { useAuth } from '../../context/AuthContext';

function KitchenAdmins() {
    const navigate = useNavigate();
    const axios = useAxiosPrivate();
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;
    const { auth } = useAuth();
    const [apiResponse, setApiResponse] = useState([]);
    const prevApiResponse = useRef(null);


    const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
    const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
    const restaurant_Id = globalAuthString.restaurant_Id ? globalAuthString.restaurant_Id : null;
    const restId = restaurant_Id ? restaurant_Id : (localAuthString ? localAuthString.restaurant_Id : null);


    const fetchUsers = () => {
        axios.get(`/recipients`)
            .then(response => {

                const data = response.data;
                console.log(data);
                setApiResponse(data.data);

            })
            .catch(error => console.error('Error fetching users:', error));

    };
    useEffect(() => {


        fetchUsers();
    }, []);

    useEffect(() => {

    }, [apiResponse]);

    const handleDeleteUser = (taskId) => {
        axios.delete(`/recipients/${taskId}`)
            .then(response => {
                console.log(response.data);
                // Refresh the task list after deletion
                fetchUsers();
                alert("Record deleted successfully!");

            })
            .catch(error => {
                console.error('Error deleting category:', error);
                alert("Error deleting record");

            });

    };

    const [tableData, setTableData] = useState({
        columns: [
            { field: 'id', header: 'Id', sortable: true },
            { field: 'recipient_name', header: 'Name', sortable: true },
            { field: 'customer_id', header: 'Customer Id', sortable: true },
            { field: 'date', header: 'Registered At', sortable: true, dataType: "date" },
            { field: 'city', header: 'City', sortable: true },
            { field: 'area', header: 'Area', sortable: true },


        ],
        items: [

            // {id: 4, name: 'Pizzas', status: 1, description: 'Pizzas Category', created_on: '2024-04-22T17:37:57.000Z'},
            // {id: 5, name: 'Sandwich', status: 1, description: 'Sandwich', created_on: '2024-04-22T18:29:13.000Z'}

        ],
        paginator: true,
        rows: 10,
        resizableColumns: true,
        showGridlines: true,
        loading: false,
        emptyMessage: "No data found."
    });

    const formattedDate = (date) => {
        const d = new Date(date);
        if (isNaN(d.getTime())) {
            // If the date is invalid, return 'Invalid Date'
            console.error('Invalid date:', date);
            return 'Invalid Date';
        } else {
            // Format the date as needed
            const month = ('0' + (d.getMonth() + 1)).slice(-2);
            const day = ('0' + d.getDate()).slice(-2);
            const year = d.getFullYear();
            return [year, month, day].join('-');
        }
    }

    useEffect(() => {
        console.log("response:", apiResponse); // Log updated apiResponse
        if (apiResponse !== prevApiResponse.current) {
            // Update prevApiResponse ref
            prevApiResponse.current = apiResponse;
            // Update rows based on apiResponse
            const updatedRows = apiResponse?.map((item, index) => ({

                id: item.id,
                recipient_name: item.recipient_name,
                mobile: item.contact_number,
                customer_id: item.customer_id,
                date: formattedDate(item.createdAt),
                city: item.city,
                area: item.area,
         

            }));

            console.log("updatedRows", updatedRows);
            // Update tableData with new rows
            setTableData(prevTableData => ({
                ...prevTableData,
                items: updatedRows,
            }));
            console.log(tableData);
        }
    },);

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <MDBox pt={6} pb={3}>

                <Grid container spacing={6}>
                    <Grid item xs={12}>

                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Recipient List
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3}>
                                <DataTable
                                    tableData={tableData}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

        </DashboardLayout>
    );
}

export default KitchenAdmins;
