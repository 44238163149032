

import { useState, useEffect } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useAuth } from '../../../context/AuthContext';
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import axios from 'axios';
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';


const useNavigateBasedOnRole = () => {
  const navigate = useNavigate();

  const navigateBasedOnRole = (userRole) => {
    if (userRole === "Super Admin") {
      navigate('/super-admin-dashboard');
    } else if (userRole === "Kitchen Admin") {
      navigate('/kitchen-admin-dashboard');
    } else {
      navigate('/unauthorized');
    }
  };

  return navigateBasedOnRole;
};
function Basic() {
  const BASE_URL = 'https://fitpranzo.com/';
  const { auth, setAuth, login } = useAuth();
  const [initialValues, setInitialValues] = useState({ email: '', password: '', rememberMe: false });
  const NavigateBasedOnRole = useNavigateBasedOnRole();
  // useEffect(() => {
  //   // Retrieve email and password from localStorage
  //   const rememberedEmail = localStorage.getItem('rememberedEmail');
  //   const rememberedPassword = localStorage.getItem('rememberedPassword');

  //   // Set the retrieved values as the initial values for the form
  //   setInitialValues({ email: rememberedEmail , password: rememberedPassword , rememberMe: false });
  //   console.log(initialValues);
  // }, []);
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const handleSubmit = async(values, { setSubmitting }) => {
  
    try {
      console.log("Sending values:", JSON.stringify(values));
      const loginResponse = await axios.post(`/login`, values, {
        hheaders: { 'Content-Type': 'application/json' },
        withCredentials: true
      });
      console.log('Login API response:', loginResponse.data);
      const userRole = loginResponse.data.userRole;
      const emp_id = loginResponse.data.emp_id;
      const accessToken = loginResponse.data.accessToken;
      const restaurant_Id = loginResponse.data.restaurant_Id;
      const isSuperAdmin = loginResponse.data.userRole == "Super Admin";
      console.log('Access Token:', accessToken);
      console.log('Refresh Token:', loginResponse.data.newRefreshToken);
      setAuth({ emp_id, userRole, accessToken, restaurant_Id, isSuperAdmin });
      //localStorage.setItem('auth', JSON.stringify({ emp_id, userRole, accessToken, restaurant_Id }));
      sessionStorage.setItem('auth', JSON.stringify({ emp_id, userRole, accessToken, restaurant_Id, isSuperAdmin }));
      login();
      NavigateBasedOnRole(userRole);
    } catch (error) {
      console.error('Error during login:', error.message);
    }
  




    console.log('Form values:', values);
    // Set submitting to false when done
    setSubmitting(false);

    // If "Remember Me" is checked, store email and password in localStorage
    if (values.rememberMe) {
      localStorage.setItem('rememberedEmail', values.email);
      localStorage.setItem('rememberedPassword', values.password);
    } else {
      // Clear localStorage if "Remember Me" is unchecked
      localStorage.removeItem('rememberedEmail');
      localStorage.removeItem('rememberedPassword');
    }
  };
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>

        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
        <Formik
 initialValues={initialValues}
 validationSchema={validationSchema}
 onSubmit={handleSubmit}
>
  {({ isSubmitting, touched, errors  }) => (
    <Form noValidate >
      <MDBox >
    <div>
        <label htmlFor="email">Email</label>
        <Field
          type="email"
          id="email"
          name="email"
          placeholder="Enter your email"
          className="form-control" // Add your custom class if needed
        />
       <small><ErrorMessage name="email" component="div" className="text-danger" /></small> 
      </div>
      <div>
        <label htmlFor="password">Password</label>
        <Field
          type="password"
          id="password"
          name="password"
          placeholder="Enter your password"
          className="form-control" // Add your custom class if needed
        />
        <small><ErrorMessage name="password" component="div" className="text-danger" /></small>
      </div>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Field type="checkbox" id="rememberMe" name="rememberMe" as={Switch} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton type="submit" variant="gradient" color="info" fullWidth disabled={isSubmitting}>
            {isSubmitting ? 'Signing in...' : 'Sign in'}
          </MDButton>
        </MDBox>
      </MDBox>
    </Form>
  )}
</Formik>

        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
