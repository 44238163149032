
import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes, { superAdminRoutes, kitchenAdminRoutes } from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import Orders from "layouts/Orders/Orders";
// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { useAuth } from './context/AuthContext';
import useAxiosPrivate from "./hook/useAxiosPrivate";
import SignIn from "layouts/authentication/sign-in";
export default function App() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const axios = useAxiosPrivate();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const { auth, loadUser, setAuth } = useAuth();

  const location = useLocation();
  const localAuthString = sessionStorage.getItem('kitchenAdminAuth');
  useEffect(() => {

    if (location.pathname === '/super-admin-dashboard') {
      if (localAuthString) {
        const localAuth = JSON.parse(localAuthString);
        sessionStorage.removeItem('kitchenAdminAuth');
        window.location.reload(); // Reload the page
      }
    }
  }, [location.pathname]);


  useEffect(() => {
    loadUser();
  }, []);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const ProtectedRoute = ({ children, allowedRoles, auth }) => {
    if (!auth?.emp_id && !auth?.accessToken ) {
      // If user is not authenticated, redirect to login page
      return navigate("/");
    }
  
    if (allowedRoles && !allowedRoles.includes(auth?.userRole)) {
      // If user role is not authorized, redirect to unauthorized page
      return navigate("/");
    }
  
    // If authenticated and authorized, render the child components
    return children;
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getRoutes(route.collapse);
  //     }
  
  //     if (route.route) {
  //       // Check if the route is for the login page
  //       if (route.route === '/') {
  //         return <Route exact path={route.route} element={route.component} key={route.key} />;
  //       }
  
  //       // Otherwise, wrap in ProtectedRoute
  //       return (
  //         <Route
  //           exact
  //           path={route.route}
  //           element={
  //             <ProtectedRoute allowedRoles={['Super Admin', 'Kitchen Admin']} auth={auth}>
  //               {route.component}
  //             </ProtectedRoute>
  //           }
  //           key={route.key}
  //         />
  //       );
  //     }
  
  //     return null;
  //   });

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  
  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );


  let userRole;



  if (auth?.isSuperAdmin && localAuthString) {
    userRole = "Kitchen Admin";
  } else if (auth && auth.userRole) {
    userRole = auth.userRole;
  }

  return (

    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          {userRole === "Super Admin" && (
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Pranzo"
              routes={superAdminRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}
          {userRole === "Kitchen Admin" && (
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Pranzo"
              routes={kitchenAdminRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              showBackToAdmin={Boolean(localAuthString)}
              onBackToAdminClick={() => {
                setAuth(JSON.parse(sessionStorage.getItem('auth')));
                sessionStorage.removeItem('kitchenAdminAuth');
                navigate('/super-admin-dashboard');
                ;
              }}
            />
          )}
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
      <Route exact path="/" element={<SignIn />} ></Route> 
      </Routes>
      {auth &&
      <Routes>
        {getRoutes(routes)}
        {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}

      </Routes>}
    </ThemeProvider>
  );
}
