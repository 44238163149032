import React,{useEffect,useRef} from 'react'
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link, useNavigate } from 'react-router-dom';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

import { useMaterialUIController } from "context";
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import DataTable from "examples/PrimeReactDatatable";
import { useState } from "react";
import { useAuth } from '../../context/AuthContext';

function Categories() {
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { auth } = useAuth();
  const [apiResponse, setApiResponse] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
 // const {restaurant_Id} = auth;
  const prevApiResponse = useRef(null);

  const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
  const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
  const restaurant_Id = globalAuthString.restaurant_Id?globalAuthString.restaurant_Id:null;
  const restId = restaurant_Id ? restaurant_Id : (localAuthString ? localAuthString.restaurant_Id : null);
  const formattedDate = (date) => {
    const d = new Date(date);
    if (isNaN(d.getTime())) {
        // If the date is invalid, return 'Invalid Date'
        console.error('Invalid date:', date);
        return 'Invalid Date';
    } else {
        // Format the date as needed
        const month = ('0' + (d.getMonth() + 1)).slice(-2);
        const day = ('0' + d.getDate()).slice(-2);
        const year = d.getFullYear();
        return [year, month, day].join('-');
    }
  }

    const fetchcategories = () => {
      axios.get(`/food_item_categories/restaurant/${restId}`,
        {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      })
        .then(response => {
  
          const data = response.data;
          console.log(data);
          setApiResponse(data);
  
        })
        .catch(error => console.error('Error fetching categories:', error));
    };
    useEffect(() => {


    fetchcategories();
  }, []);


  const handleDeleteCategory = (taskId) => {
    axios.delete(`/food_item_categories/${taskId}`)
      .then(response => {
        console.log(response.data);
        // Refresh the task list after deletion
        fetchcategories();
        alert("Category deleted successfully!");
        
      })
      .catch(error => {
        console.error('Error deleting category:', error);
        alert("Error deleting category");
       
      });
      
  };
  const handleEditItem = (taskId) => {
    // Navigate to the "add-item" URL with the taskId as a query parameter
    navigate(`/add-category?taskId=${taskId}`);
  };
 
  const [tableData, setTableData] = useState({
    columns: [
        { field: "id", header: "ID", sortable: true},
      { field: "name", header: "Name", sortable: true},
      { field: "status", header: "Status", sortable: true},
      { field: "description", header: "Description", sortable: true },
      { field: "date", header: "Created On", sortable: true,dataType:"date"},
       { field: "action", header: "action", sortable: false,},
    ],
    items: [
    
      // {id: 4, name: 'Pizzas', status: 1, description: 'Pizzas Category', created_on: '2024-04-22T17:37:57.000Z'},
      // {id: 5, name: 'Sandwich', status: 1, description: 'Sandwich', created_on: '2024-04-22T18:29:13.000Z'}
     
    ],
    paginator: true,
    rows: 10,
    resizableColumns: true,
    showGridlines: true,
    loading: false,
    emptyMessage: "No data found."
  });
  useEffect(() => {
    console.log("response:", apiResponse); // Log updated apiResponse
    
    if (apiResponse !== prevApiResponse.current) {
      // Update prevApiResponse ref
      prevApiResponse.current = apiResponse;
    const updatedRows = apiResponse?.map((item, index) => ({
      id: item.id,
      name: item.name,
      status: item.is_active===1?"Active":"Not Active",
      description: item.description,
      date: formattedDate(item.created_at),
    
      action: (
        <>
          <MDButton variant="text" color="error"onClick={() => handleDeleteCategory(item.id)}>
            <Icon>delete</Icon>&nbsp;delete
          </MDButton>
          <MDButton variant="text" color="success" onClick={() => handleEditItem(item.id)}>
            <Icon>edit</Icon>&nbsp;edit
          </MDButton>
        </>
      ),
    }));
 
  console.log("updatedRows",updatedRows);
    // Update tableData with new rows
    setTableData(prevTableData => ({
      ...prevTableData,
      items: updatedRows,
    }));
    console.log(tableData);
  }
  }, [apiResponse]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <button type="button" className="btn btn-primary mt-3 ms-5" data-mdb-ripple-init="true">
  <Link to="/add-category" className="text-white text-decoration-none">Add New</Link>
</button>
      <MDBox pt={6} pb={3}>
        
        <Grid container spacing={6}>
          <Grid item xs={12}>
         
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Food Categories
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
       tableData={tableData}
      //  //onEditItem={handleEditCategory}
      //  onDeleteItem={handleDeleteCategory}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    
    </DashboardLayout>
  );
}

export default Categories;
