import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Link } from 'react-router-dom';
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
// Images
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { useMaterialUIController } from "context";


function Extras() {
  
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  //const { columns, rows } = authorsTableData();
  //const { columns: pColumns, rows: pRows } = projectsTableData();
  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
  Author.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  };
  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  Job.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };
  const columns = [
    { Header: "ID", accessor: "ID", width: "10%", align: "left" },
    { Header: "Name", accessor: "Name", align: "left" },
    { Header: "Price", accessor: "Price", align: "left" },
    { Header: "Category", accessor: "Category", align: "center" },
    { Header: "Status", accessor: "Status", align: "center" },
    { Header: "Created On", accessor: "Created_on", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];
  const rows = [
    {
      ID: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      1
    </MDTypography>
    ),
      Name: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        Small
      </MDTypography>
      ),
      Price: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      1000
    </MDTypography>
    ),
      Category: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      Size
    </MDTypography>
    ),
     Status: (
        <MDBox ml={-1}>
          <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
        </MDBox>
      ),
      Created_on: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          23/04/18
        </MDTypography>
      ),
      action: (
        <>
        <MDButton variant="text" color="error">
            <Icon>delete</Icon>&nbsp;delete
          </MDButton>
       
        <MDButton variant="text" color="success">
          <Icon>edit</Icon>&nbsp;edit
        </MDButton>
    </>
      ),
    },
    {
        ID: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        1
      </MDTypography>
      ),
        Name: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
         Medium
        </MDTypography>
        ),
        Price: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      2000
    </MDTypography>
    ),
        Category: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      Size
    </MDTypography>
    ),
       Status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        Created_on: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        action: (
          <>
          <MDButton variant="text" color="error">
              <Icon>delete</Icon>&nbsp;delete
            </MDButton>
         
          <MDButton variant="text" color="success">
            <Icon>edit</Icon>&nbsp;edit
          </MDButton>
      </>
        ),
      },
      {
        ID: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        1
      </MDTypography>
      ),
        Name: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
  Large
        </MDTypography>
        ),
        Price: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      3000
    </MDTypography>
    ),
       Status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent="Active" color="success" variant="gradient" size="sm" />
          </MDBox>
        ),
        Category: (<MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      Size
    </MDTypography>
    ),
        Created_on: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            23/04/18
          </MDTypography>
        ),
        action: (
          <>
          <MDButton variant="text" color="error">
              <Icon>delete</Icon>&nbsp;delete
            </MDButton>
         
          <MDButton variant="text" color="success">
            <Icon>edit</Icon>&nbsp;edit
          </MDButton>
      </>
        ),
      },

  ];
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <button type="button" className="btn btn-primary mt-3 ms-5" data-mdb-ripple-init="true">
  <Link to="/add-extras-category" className="text-white text-decoration-none">Add New</Link>
</button>
      <MDBox pt={6} pb={3}>
        
        <Grid container spacing={6}>
          <Grid item xs={12}>
         
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Variations & Extras
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                 table={{ columns, rows }}
                 showTotalEntries={true}
                 isSorted={true}
                 noEndBorder
                 entriesPerPage={true}
                 canSearch={true}
                 pagination={true}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    
    </DashboardLayout>
  );
}

export default Extras;
