import React from 'react';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBCard,
  MDBFile
} from 'mdb-react-ui-kit';
import {Link} from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
export default function App() {
  return (
    <>
    <DashboardLayout>
    <DashboardNavbar />
    <button type="button" className="btn btn-primary mt-3 ms-5" data-mdb-ripple-init="true">
  <Link to="/extras-categories" className="text-white text-decoration-none">Extras  List</Link>
</button>
    < MDBCard className='p-5 mt-3'>
    <form>
    <MDBRow className='mb-4'>
        <MDBCol>
        <label className="form-label" htmlFor="package">Name</label>
          <MDBInput id='form6Example1' />
        </MDBCol>
        <MDBCol>
        <label className="form-label" htmlFor="package">Price</label>
          <MDBInput id='form6Example1' />
        </MDBCol>
     
      </MDBRow>
      <MDBRow className='mb-4'>
        <MDBCol>
        <label className="form-label" htmlFor="package">Category</label>
        <select className="browser-default custom-select" id="package"> 
  <option selected></option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select> 
        </MDBCol>
        <MDBCol>
        <label className="form-label" htmlFor="package">Status</label>
        <select className="browser-default custom-select" id="package"> 
  <option selected></option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select> 

        </MDBCol>
      </MDBRow>

      


      {/* <MDBCheckbox
        wrapperClass='d-flex justify-content-center mb-4'
        id='form6Example8'
        label='Create an account?'
        defaultChecked
      /> */}

<button className='mb-4 mt-5  btn btn-primary w-100' type='submit' >
       Add
      </button>
    </form>
    </MDBCard>
    </DashboardLayout>
    </>
  );
}