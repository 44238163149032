import React, { useState, useEffect} from 'react';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
} from 'mdb-react-ui-kit';
import { Link, useLocation, useNavigate  } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { FaEye, FaEyeSlash, FaTrash, FaPlus } from 'react-icons/fa';
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import { useFormik, Field, ErrorMessage, FormikProvider } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useAuth } from '../../context/AuthContext';
//import axios from 'axios';



export default function AddItem() {
  const location = useLocation();
  const navigate  = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const taskId = searchParams.get('taskId');
  const axios = useAxiosPrivate();
  const { auth } = useAuth();


  const [itemExtras, setitemExtras] = useState([{ name: '', price: '', is_active: 1 }]);
  const [variations, setVariations] = useState([{ name: '', price_adjustment: '', is_active: 1 }]);
  const [foodItemsData, setFoodItemsData] = useState([]);
  const isUpdateForm = Object.keys(foodItemsData).length > 0;
  const [categories, setCategories] = useState([]);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };
  const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
  const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
  const restaurant_Id = globalAuthString.restaurant_Id?globalAuthString.restaurant_Id:null;
  const restId = restaurant_Id ? restaurant_Id : (localAuthString ? localAuthString.restaurant_Id : null);

  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`/food_items/${taskId}`)
        .then(response => {
          console.log(response.data);
          const data = response.data;
          console.log(data.data);
          setFoodItemsData(data.data);
          const initialValues = {
            title: data.data.title || '',
            is_available: data.data.is_available || null,
            price: data.data.price || null,
            categoryId: data.data.food_item_category.id || null,
            foodItemImage: data.data.image_pathLocation || '',
            description: data.data.description || '',
            restaurant_Id: data.data.restaurant_Id || '',
            userId: data.data.userId || '',
            extras: data.data.extras_extras_food_itemTofood_item || itemExtras,
            Variations: data.data.variations_variations_food_itemTofood_item || variations,
          };
          formik.setValues(initialValues);
        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);

    useEffect(() => {
      console.log("Data to be updated:", foodItemsData);
  
    }, [foodItemsData]);


  const fetchcategories = () => {
    axios.get(`/food_item_categories/restaurant/${restId}`)
      .then(response => {

        const data = response.data;
        console.log(data);
        setCategories(data);

      })
      .catch(error => console.error('Error fetching categories:', error));
  };
  useEffect(() => {
    
    fetchcategories();
  }, []);

  useEffect(() => {
    console.log("Cateories:", categories);
  }, [categories]);

  const addRecord = async (values) => {

    try {
      console.log("Making a POST request to fooditems with data:", values);

      const response = await axios.post('/food_items', values, config);

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);
      alert("Added Successfully!");
      
      setTimeout(function () {

        navigate('/kitchen-admin-dashboard');
      }, 3000);
    } catch (error) {
      console.error("Error while making the POST request:", error.message);
      alert("Error Adding Record");
    }
  };

  const updateRecord = async (values) => {

    try {
      console.log(`Making a PUT request to fooditems with data:`, values);

      const response = await axios.put(`/food_items/${foodItemsData.id}`, values, config);

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);
      alert("Updated Successfully!");
      setTimeout(function () {

        //  handleButtonUpdate();
      }, 3000);
    } catch (error) {

      alert("Error Updating Record");
    }
  };



  const formik = useFormik({
    initialValues: {
      title: foodItemsData ? foodItemsData.title : '',
      is_available: foodItemsData ? foodItemsData.is_available : null,
      price: foodItemsData ? foodItemsData.price : null,
      categoryId: foodItemsData ? foodItemsData.no_of_rooms : null,
      foodItemImage: foodItemsData ? foodItemsData.image_pathLocation : '',
      description: foodItemsData ? foodItemsData.description : '',
      restaurant_Id:restaurant_Id> 0 ? restaurant_Id : '',
      userId:restId ? restId : '',
      extras: isUpdateForm ? foodItemsData.extras : itemExtras,
      Variations: isUpdateForm ? foodItemsData.variations: variations,
    },

    validationSchema: Yup.object({
      title: Yup.string().required('This field is required'),
      is_available: Yup.number().required('This field is required'),
      description: Yup.string().required('This field is required'),
      price: Yup.number().required('This field is required'),
      categoryId: Yup.number().required('This field is required'),
      foodItemImage: Yup.mixed().required('This field is required'),

    }),
    onSubmit: (values) => {
      // Handle form submission logic, e.g., send data to the server
      isUpdateForm ? updateRecord(values) : addRecord(values);
      console.log("extras", formik.values.extras);
      console.log("var", formik.values.Variations);
      navigate('/food-items');
     
    },
  });
  const [extraVisibility, setExtraVisibility] = useState(Array.from({ length: formik.values.extras.length }).fill(false));
  const [variationVisibility, setVariationVisibility] = useState(Array.from({ length: formik.values.Variations.length }).fill(false));

  const handleAddMore = () => {
    formik.setFieldValue('extras', [
      ...formik.values.extras,
      { name: '', price: '', is_active: 1 } // Initial values for a new extra
    ]);
  };
  const ExtrasNameChange = (index, value) => {
    const updatedExtras = [...formik.values.extras];
    updatedExtras[index].name = value;
    formik.setFieldValue('extras', updatedExtras);
  };

  // Function to update extras price
  const ExtrasPriceChange = (index, value) => {
    const updatedExtras = [...formik.values.extras];
    updatedExtras[index].price = value;
    formik.setFieldValue('extras', updatedExtras);
  };

  const ExtrasStatusChange = (index) => {
    const updatedExtras = [...formik.values.extras];
    updatedExtras[index].is_active = updatedExtras[index].is_active === 1 ? 0 : 1;
    setExtraVisibility((prevVisibility) => {
      const updatedVisibility = [...prevVisibility];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
    formik.setFieldValue('extras', updatedExtras);
  };

  // Function to remove an extra
  const handleDelete = (index) => {
    const updatedExtras = [...formik.values.extras];
    updatedExtras.splice(index, 1);
    formik.setFieldValue('extras', updatedExtras);
  };

  const AddMoreVariation = () => {
    formik.setFieldValue('Variations', [
      ...formik.values.Variations,
      { name: '', price_adjustment: '', is_active: 1 } // Initial values for a new extra
    ]);
  };

  const DeleteVariation = (index) => {
    const updatedVariations = [...formik.values.Variations];
    updatedVariations.splice(index, 1);
    formik.setFieldValue('Variations', updatedVariations);
  };


  const VariationsNameChange = (index, value) => {
    const updatedvariations = [...formik.values.Variations];
    updatedvariations[index].name = value;
    formik.setFieldValue('Variations', updatedvariations);
  };

  const VariationsPriceChange = (index, value) => {
    const updatedvariations = [...formik.values.Variations];
    updatedvariations[index].price_adjustment = value;
    formik.setFieldValue('Variations', updatedvariations);
  };
  const VariationsStatusChange = (index) => {
    const updatedVariations = [...formik.values.Variations];
    updatedVariations[index].is_active = updatedVariations[index].is_active === 1 ? 0 : 1;
    setVariationVisibility((prevVisibility) => {
      const updatedVisibility = [...prevVisibility];
      updatedVisibility[index] = !updatedVisibility[index];
      return updatedVisibility;
    });
    formik.setFieldValue('Variations', updatedVariations);
  };
  return (
    <>

      <DashboardLayout>
        <DashboardNavbar />
        <FormikProvider value={formik}>
          <button type="button" className="btn btn-primary mt-3 ms-5" data-mdb-ripple-init="true">
            <Link to="/restaurant-food-items" className="text-white text-decoration-none">Food Item List</Link>
          </button>
          < MDBCard className='p-5 mt-3'>
            <form onSubmit={formik.handleSubmit}>
              <MDBRow className='mb-4'>
                <MDBCol>
                  <Form.Group controlId="title">
                    <label className="form-label" htmlFor="title">Name</label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      name="title"
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="inputclass"
                      isInvalid={formik.touched.title && formik.errors.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>
                </MDBCol>
                <MDBCol>
                  <Form.Group controlId="is_available">
                    <label className="form-label" htmlFor="is_available">Status</label>
                    <Field
                      as="select"
                      className="browser-default custom-select"
                      id="is_available"
                      name="is_available"
                      value={formik.values.is_available}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.is_available && formik.errors.is_available}
                    >
                      <option value="" disabled selected>Select status</option>
                      <option value={1}>Available</option>
                      <option value={2}>Not Available</option>

                    </Field>
                    <small><ErrorMessage name="is_available" component="div" className="text-danger" /></small>
                  </Form.Group>

                </MDBCol>
              </MDBRow>

              <MDBRow className='mb-4'>
                <MDBCol>
                  <Form.Group controlId="price">
                    <label className="form-label" htmlFor="price">Price</label>
                    <input
                      id="price"
                      name="price"
                      type="number"
                      value={formik.values.price}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control"
                    // You can add more props as needed
                    />
                    <ErrorMessage name="price" component="div" className="text-danger" />
                  </Form.Group>
                </MDBCol>
                <MDBCol>
                  <Form.Group controlId="categoryId">
                    <label className="form-label" htmlFor="categoryId">Category</label>
                    <Field
                      as="select"
                      className="browser-default custom-select"
                      id="categoryId"
                      name="categoryId"
                      value={formik.values.categoryId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.categoryId && formik.errors.categoryId}
                    >
                      <option value="" disabled selected>Select category</option>
                      {categories.map(category => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                      ))}
                    </Field>
                    <small><ErrorMessage name="categoryId" component="div" className="text-danger" /></small>
                  </Form.Group>
                </MDBCol>
              </MDBRow>
              <MDBRow className='mb-4'>
                <MDBCol>
                  <Form.Group controlId="description">
                    <label className="form-label" htmlFor="description">Description</label>
                    <textarea
                      id="description"
                      name="description"
                      rows={1}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="form-control"
                    // You can add more props as needed
                    />
                    <small> <ErrorMessage name="description" component="div" className="text-danger" /></small>
                  </Form.Group>
                </MDBCol>
                <MDBCol>
                  <Form.Group controlId="foodItemImage">
                    <label className="form-label" htmlFor="foodItemImage">Featured Image</label>
                    <input
                      type="file"
                      name="foodItemImage"
                      accept="image/*"
                      onChange={(event) => formik.setFieldValue('foodItemImage', event.currentTarget.files[0])}
                      className={` inputclass form-control ${formik.touched.foodItemImage && formik.errors.foodItemImage ? 'is-invalid' : ''
                        }`}
                    />
                    <div className="invalid-feedback">{formik.errors.foodItemImage}</div>
                  </Form.Group>
                </MDBCol>
              </MDBRow>

              <MDBRow className='mb-4'>

                <MDBCol>
                  <label className="form-label me-5" >Extras</label>{" "}
                  <button type="button" className="btn-sm btn-outline-success btn-floating  ms-5" data-mdb-ripple-init data-mdb-ripple-color="dark" onClick={handleAddMore}>
                    <FaPlus /></button>

                  <table className='p-5'>
                    <thead className='bg-primary '>
                      <tr >
                        <td style={{ paddingRight: '10px' }}><label className="form-label" >Name</label></td>
                        <td style={{ paddingRight: '20px' }}><label className="form-label" >Price</label></td>
                        <td style={{ paddingRight: '60px' }}><label className="form-label" >Actions</label></td>
                      </tr>
                    </thead>
                    <tbody >
                      {formik.values.extras?.map((extra, index) => (
                        <tr key={index} style={{ marginTop: '10px' }}>
                          <td style={{ paddingRight: '10px', paddingTop: '10px' }}>
                            <MDBInput
                              value={extra.name}
                              onChange={(e) => ExtrasNameChange(index, e.target.value)}
                            />
                          </td>
                          <td style={{ paddingRight: '60px', paddingTop: '10px' }}>
                            <MDBInput
                              value={extra.price}
                              onChange={(e) => ExtrasPriceChange(index, e.target.value)}
                            />
                          </td>
                          <td style={{ paddingRight: '10px', paddingTop: '10px' }}>
                            <input type="number" value={extra.is_active} hidden />
                            <span onClick={() => ExtrasStatusChange(index)} style={{ paddingRight: '10px' }}>
                              {extraVisibility[index] ? <FaEyeSlash /> : <FaEye />}
                            </span>
                            <span onClick={() => handleDelete(index)}>
                              <FaTrash fontSize={18} color='red' />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </MDBCol>

                <MDBCol>
                  <label className="form-label me-5" >Variations</label>{" "}
                  <button type="button" className="btn-sm btn-outline-success btn-floating  ms-5" data-mdb-ripple-init data-mdb-ripple-color="dark" onClick={AddMoreVariation}>
                    <FaPlus /></button>
                  <table className='p-5'>
                    <thead className='bg-primary '>
                      <tr >
                        <td style={{ paddingRight: '10px' }}><label className="form-label" >Name</label></td>
                        <td style={{ paddingRight: '20px' }}><label className="form-label" >Price</label></td>
                        <td style={{ paddingRight: '60px' }}><label className="form-label" >Actions</label></td>
                      </tr>
                    </thead>
                    <tbody >
                      {formik.values.Variations?.map((item, index) => (
                        <tr key={index} style={{ marginTop: '10px' }}>
                          <td style={{ paddingRight: '10px', paddingTop: '10px' }}>
                            <MDBInput
                              value={item.name}
                              onChange={(e) => VariationsNameChange(index, e.target.value)}
                            />
                          </td>
                          <td style={{ paddingRight: '60px', paddingTop: '10px' }}>
                            <MDBInput
                              value={item.price_adjustment}
                              onChange={(e) => VariationsPriceChange(index, e.target.value)}
                            />
                          </td>
                          <td style={{ paddingRight: '10px', paddingTop: '10px' }}>
                            <input type="number" value={item.is_active} hidden />
                            <span onClick={() => VariationsStatusChange(index)} style={{ paddingRight: '10px' }}>
                              {variationVisibility[index] ? <FaEyeSlash /> : <FaEye />}
                            </span>
                            <span onClick={() => DeleteVariation(index)}>
                              <FaTrash fontSize={18} color='red' />
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </MDBCol>
              </MDBRow>
              <button className='mb-4 mt-5 btn btn-primary w-100' type='submit' >
                {isUpdateForm ? 'Update' : 'Add'}
              </button>
            </form>
          </MDBCard>
        </FormikProvider>
      </DashboardLayout>

    </>
  );
}