import React, { useState, useEffect } from 'react';
import {
    MDBRow,
    MDBCol,
    MDBInput,
    MDBCard,
    MDBTypography,
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import { useFormik, Field, ErrorMessage, FormikProvider } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useAuth } from '../../context/AuthContext';
//import axios from 'axios';



export default function AddItem() {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const taskId = searchParams.get('taskId');
    const axios = useAxiosPrivate();
    const { auth } = useAuth();

    const [toggleOneModal, setToggleOneModal] = useState(false);
    const [UpdateData, setUpdateData] = useState([]);
    const [walletData, setWalletData] = useState({});
    const [walletBalance, setWalletBalance] = useState(null);
    const isUpdateForm = Object.keys(UpdateData).length > 0;
    //const {emp_id}= auth;
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };
    const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
    const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
    const emp_id = globalAuthString.emp_id ? globalAuthString.emp_id : null;
    const empId = localAuthString ? localAuthString.emp_id : (emp_id ? emp_id : null);


    useEffect(() => {
        if (taskId) {
            axios.get(`/customers/${taskId}`)
                .then(response => {
                    console.log(response.data);
                    const data = response.data.data;

                    if (data) {
                        setUpdateData(data);
                        setWalletData(data.wallet);
                        const initialValues = {
                            firstName: data.firstName || '',
                            lastName: data.lastName || '',
                            email: data.email || '',
                            mobile_number: data.mobile_number || null,
                            address: data.address || '',
                            wallet_balance: data.wallet_balance || null,
                            points: data.points || null,
                            user_category: data.user_category || '',
                            status: data.status || '',

                        };
                        formik.setValues(initialValues);
                    } else {
                        console.log("No data found for the user ID:", empId);
                    }
                })
                .catch(error => {
                    console.error('Error fetching record:', error);
                });
        }

    }, [taskId]);


    const updateRecord = async (values) => {

        try {
            console.log(`Making a PUT request to fooditems with data:`, values);

            const response = await axios.put(`/customers/${taskId}`, values, config);

            console.log("Response status:", response.status);
            console.log("Response data:", response.data);
            alert("Updated Successfully!");
            setTimeout(function () {

                //  handleButtonUpdate();
            }, 3000);
        } catch (error) {

            alert("Error Updating Record");
        }
    };


    const updateBalance = async () => {

        try {
            console.log(`Making a Patch request to transactions with data`);
            let values = JSON.stringify({
                transaction_type: "credit",
                amount: walletBalance,
                currency: "Rupees"
            })
            const response = await axios.patch(`/wallet/${walletData.id}/balance`, values, config);

            console.log("Response status:", response.status);
            console.log("Response data:", response.data);
            alert("Balance Updated Successfully!");
            setTimeout(function () {

                navigate('/restaurant-customers');
            }, 3000);
        } catch (error) {

            alert("Error Updating Balance");
        }
    };



    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            mobile_number: null,
            address: '',
            wallet_balance: null,
            points: null,
            user_category: '',
            status: '',
        },

        validationSchema: Yup.object({


            // firstName: Yup.string().required('This field is required'),
            // lastName: Yup.string().required('This field is required'),
            // email: Yup.string().required('This field is required'),
            // mobile_number: Yup.string().required('This field is required'),
            // address: Yup.string().required('This field is required'),
            points: Yup.number('This field is required'),
            user_category: Yup.string().required('This field is required'),
            status: Yup.string().required('This field is required'),
        }),
        onSubmit: (values) => {

            updateRecord(values);

            navigate('/restaurant-customers');

        },
    });

    return (
        <>

            <DashboardLayout>
                <DashboardNavbar />
                <FormikProvider value={formik}>

                    < MDBCard className='p-5 mt-3'>
                        <form onSubmit={formik.handleSubmit}>

                            <MDBRow className='mb-4'>
                                <MDBCol>
                                    <Form.Group controlId="firstName">
                                        <label className="form-label" htmlFor="firstName">First Name</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter First Name"
                                            name="firstName"
                                            value={formik.values.firstName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.firstName && formik.errors.firstName}
                                            disabled
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.firstName}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </MDBCol>
                                <MDBCol>
                                    <Form.Group controlId="lastName">
                                        <label className="form-label" htmlFor="lastName">Last Name</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Last Name"
                                            name="lastName"
                                            value={formik.values.lastName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.lastName && formik.errors.lastName}
                                            disabled
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.lastName}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </MDBCol>
                            </MDBRow>

                            <MDBRow className='mb-4'>
                                <MDBCol>
                                    <Form.Group controlId="address">
                                        <label className="form-label" htmlFor="address">Address</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Address"
                                            name="address"
                                            value={formik.values.address}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.address && formik.errors.address}
                                            disabled
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.address}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </MDBCol>
                                <MDBCol>
                                    <Form.Group controlId="status">
                                        <label className="form-label" htmlFor="status">Status</label>
                                        <Field
                                            as="select"
                                            className="browser-default custom-select"
                                            id="status"
                                            name="status"
                                            value={formik.values.status}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.status && formik.errors.status}
                                        >
                                            <option value="" disabled selected>Select status</option>
                                            <option value='active'>Active</option>
                                            <option value='suspended'>InActive</option>

                                        </Field>
                                        <small><ErrorMessage name="status" component="div" className="text-danger" /></small>
                                    </Form.Group>
                                </MDBCol>
                            </MDBRow>


                            <MDBRow className='mb-4'>
                                <MDBCol>
                                    <Form.Group controlId="email">
                                        <label className="form-label" htmlFor="email">Email</label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter Email"
                                            name="email"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.email && formik.errors.email}
                                            disabled
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.email}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </MDBCol>
                                <MDBCol>
                                    <Form.Group controlId="mobile_number">
                                        <label className="form-label" htmlFor="mobile_number">Contact Number</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Contact"
                                            name="mobile_number"
                                            value={formik.values.mobile_number}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.mobile_number && formik.errors.mobile_number}
                                            disabled
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.mobile_number}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </MDBCol>
                            </MDBRow>


                            <MDBRow className='mb-4'>

                                <MDBCol>
                                    <Form.Group controlId="points">
                                        <label className="form-label" htmlFor="points">Points</label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter Points"
                                            name="points"
                                            value={formik.values.points}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.points && formik.errors.points}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.points}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                </MDBCol>
                                <MDBCol>
                                    <Form.Group controlId="user_category">
                                        <label className="form-label" htmlFor="user_category">User Category</label>
                                        <Field
                                            as="select"
                                            className="browser-default custom-select"
                                            id="user_category"
                                            name="user_category"
                                            value={formik.values.user_category}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.user_category && formik.errors.user_category}
                                        >
                                            <option value="" disabled selected>Select user category</option>
                                            <option value='New'>New</option>
                                            <option value='Regular'>Regular</option>
                                            <option value='Trusted'>Trusted</option>

                                        </Field>
                                        <small><ErrorMessage name="user_category" component="div" className="text-danger" /></small>
                                    </Form.Group>
                                </MDBCol>
                            </MDBRow>


                            <button className='mb-4 mt-5 btn btn-primary w-100' type='submit' >
                                Update
                            </button>
                        </form>
                    </MDBCard>
                </FormikProvider>



                <div className=' d-flex justify-content-center align-items-center flex-column' >
                    <div className='mb-4 mt-5 bg-primary text-white d-flex justify-content-center align-items-center' style={{ borderRadius: '5px', width: '97%', height: '58px', }}>Customer Wallet</div>
                    <div className='mb-4 mt-5  d-flex justify-content-between align-items-center ' style={{ width: '97%' }}>
                        <h5 className='col-sm-3'>Balance in Rupees:</h5>

                        <Form.Group className='col-sm-9' >
                            {/* <label className="form-label" htmlFor="wallet_balance">Wallet Balance </label>  */}
                            <Form.Control
                                style={{ height: '60px', borderRadius: '11px' }}
                                type="number"
                                name="wallet_balance"
                                value={walletData.balance}
                                disabled
                            />
                        </Form.Group>

                    </div>

                    <button className='mb-4 mt-5 btn btn-primary btn-lg' onClick={() => setToggleOneModal(!toggleOneModal)}>
                        Top Up
                    </button>

                    <MDBModal open={toggleOneModal} onClose={() => setToggleOneModal(false)} tabIndex='-1'>
                        <MDBModalDialog centered>
                            <MDBModalContent>
                                <MDBModalHeader>
                                    <MDBModalTitle>Top Up Customer Balance</MDBModalTitle>
                                    <MDBBtn
                                        className='btn-close'
                                        color='none'
                                        onClick={() => setToggleOneModal(!toggleOneModal)}
                                        style={{ fontSize: '20px', border: 'none', color: 'black', backgroundColor: 'transparent' }}
                                    >X</MDBBtn>
                                </MDBModalHeader>
                                <MDBModalBody>
                                    <Form.Group className="col-sm-9">
                                        <label className="form-label" htmlFor="walletBalance">Amount</label>
                                        <Form.Control
                                            style={{ height: '60px', borderRadius: '11px' }}
                                            type="number"
                                            name="walletBalance"
                                            placeholder="Enter amount to top up"
                                            value={walletBalance}
                                            onChange={(e) => setWalletBalance(e.target.value)} // Correct way to handle the change event
                                        />
                                    </Form.Group>
                                </MDBModalBody>
                                <MDBModalFooter>
                                    <button className='mb-4 mt-5 btn btn-primary btn-md'
                                        onClick={() => {
                                            updateBalance();
                                        }}
                                    >
                                        Top Up
                                    </button>
                                </MDBModalFooter>
                            </MDBModalContent>
                        </MDBModalDialog>
                    </MDBModal>

                </div>
            </DashboardLayout>
        </>
    );
}