/**
=========================================================
* Material Dashboard 2  React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ReportsLineChart configurations
import configs from "examples/Charts/LineCharts/ReportsLineChart/configs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function ReportsLineChart({ color, title, description, date, chart, ReportChart,yAxisStepSize }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {}, yAxisStepSize || null);

  const chartComponent = useMemo(() => (
    <MDBox
      variant="gradient"
      bgColor={color}
      borderRadius="lg"
      coloredShadow={color}
      py={2}
      pr={0.5}
      mt={ReportChart ? 1 : -5}
      height={ReportChart ? "25.5rem" : "12.5rem"}
    >
      <Line data={data} options={options} redraw />
    </MDBox>
  ), [color, chart, data, options, ReportChart]);
  return (
    // <Card sx={{ height: "100%" }}>
    //   {ReportChart ?
    //   (<>
    //     <MDBox pt={3} pb={1} px={1}>
    //     <MDTypography variant="h6" textTransform="capitalize">
    //       {title}
    //     </MDTypography>
    //     <MDTypography component="div" variant="button" color="text" fontWeight="light">
    //       {description}
    //     </MDTypography>
    
    //   </MDBox>
    //   <MDBox padding="1rem">
    //     {useMemo(
    //       () => (
    //         <MDBox
    //           variant="gradient"
    //           bgColor={color}
    //           borderRadius="lg"
    //           coloredShadow={color}
    //           py={2}
    //           pr={0.5}
    //           mt={1}
    //           height="25.5rem"
    //         >
    //           <Line data={data} options={options} redraw />
    //         </MDBox>
    //       ),
    //       [chart, color]
    //     )}
 
    //   </MDBox>
    //   </>):
    //   (
    //   <MDBox padding="1rem">
    //     {useMemo(
    //       () => (
    //         <MDBox
    //           variant="gradient"
    //           bgColor={color}
    //           borderRadius="lg"
    //           coloredShadow={color}
    //           py={2}
    //           pr={0.5}
    //           mt={-5}
    //           height="12.5rem"
    //         >
    //           <Line data={data} options={options} redraw />
    //         </MDBox>
    //       ),
    //       [chart, color]
    //     )}
    //     <MDBox pt={3} pb={1} px={1}>
    //       <MDTypography variant="h6" textTransform="capitalize">
    //         {title}
    //       </MDTypography>
    //       <MDTypography component="div" variant="button" color="text" fontWeight="light">
    //         {description}
    //       </MDTypography>
    //       <Divider />
    //       <MDBox display="flex" alignItems="center">
    //         <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
    //           <Icon>schedule</Icon>
    //         </MDTypography>
    //         <MDTypography variant="button" color="text" fontWeight="light">
    //           {date}
    //         </MDTypography>
    //       </MDBox>
    //     </MDBox>
    //   </MDBox>
    //   )
    //   }
    // </Card>
    <Card sx={{ height: "100%" }}>
    {ReportChart ? (
      <>
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography component="div" variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
        </MDBox>
        <MDBox padding="1rem">
          {chartComponent}
        </MDBox>
      </>
    ) : (
      <MDBox padding="1rem">
        {chartComponent}
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography component="div" variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {date}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    )}
  </Card>
  );
}

// Setting default values for the props of ReportsLineChart
ReportsLineChart.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the ReportsLineChart
ReportsLineChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  ReportChart: PropTypes.bool.isRequired,
  yAxisStepSize: PropTypes.number,
};

export default ReportsLineChart;
