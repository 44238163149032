
import PrimeReact from 'primereact/api';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { Slider } from 'primereact/slider';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import './DataTable.css';

AdvancedDataTable.propTypes = {
    tableData: PropTypes.shape({
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                field: PropTypes.string.isRequired,
                header: PropTypes.string.isRequired,
                // Additional PropTypes for column properties if needed
            })
        ).isRequired,
        items: PropTypes.arrayOf(
            PropTypes.objectOf(
                PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.number,
                    // Additional PropTypes for item properties if needed
                ])
            )
        ).isRequired,
        paginator: PropTypes.bool.isRequired,
        rows: PropTypes.number.isRequired,
        // Additional PropTypes for DataTable properties if needed
    }).isRequired,
    onEditItem: PropTypes.func.isRequired,
    onDeleteItem: PropTypes.func.isRequired,
};

export default function AdvancedDataTable({ tableData, onEditItem, onDeleteItem }) {
    const { columns, items, ...otherProps } = tableData;
    console.log("rows in dtattbl", items);
    const [visibleColumns, setVisibleColumns] = useState(columns);
    const [dataRows, setDataRows] = useState(items);
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [representatives] = useState([
        { name: 'Amy Elsner', image: 'amyelsner.png' },
        { name: 'Anna Fali', image: 'annafali.png' },
        { name: 'Asiya Javayant', image: 'asiyajavayant.png' },
        { name: 'Bernardo Dominic', image: 'bernardodominic.png' },
        { name: 'Elwin Sharvill', image: 'elwinsharvill.png' },
        { name: 'Ioni Bowcher', image: 'ionibowcher.png' },
        { name: 'Ivan Magalhaes', image: 'ivanmagalhaes.png' },
        { name: 'Onyama Limba', image: 'onyamalimba.png' },
        { name: 'Stephen Shaw', image: 'stephenshaw.png' },
        { name: 'XuXue Feng', image: 'xuxuefeng.png' }
    ]);
    const [statuses] = useState(['unqualified', 'qualified', 'new', 'negotiation', 'renewal']);
    const dt = useRef(null);
    const handleEdit = (itemId) => {

        onEditItem(itemId);
    };

    const handleDelete = (itemId) => {

        onDeleteItem(itemId);
    };
    const CustomerService = [{
        id: 1000,
        name: 'James Butt',
        country: {
            name: 'Algeria',
            code: 'dz'
        },
        company: 'Benton, John B Jr',
        date: '2015-09-13',
        status: 'unqualified',
        verified: true,
        activity: 17,
        representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
        },
        balance: 70663
    },
    {
        id: 1000,
        name: 'James Butt',
        country: {
            name: 'Algeria',
            code: 'dz'
        },
        company: 'Benton, John B Jr',
        date: '2015-09-13',
        status: 'unqualified',
        verified: true,
        activity: 17,
        representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
        },
        balance: 70663
    },
    {
        id: 1000,
        name: 'muth',
        country: {
            name: 'Algeria',
            code: 'dz'
        },
        company: 'Benton, John B Jr',
        date: '2019-05-03',
        status: 'unqualified',
        verified: true,
        activity: 17,
        representative: {
            name: 'Ioni Bowcher',
            image: 'ionibowcher.png'
        },
        balance: 70663
    },];

    const getSeverity = (status) => {
        switch (status) {
            case 'unqualified':
                return 'danger';

            case 'qualified':
                return 'success';

            case 'new':
                return 'info';

            case 'negotiation':
                return 'warning';

            case 'renewal':
                return null;
        }
    };

    useEffect(() => {

        setCustomers(getCustomers(CustomerService));
        setLoading(false);

        initFilters(visibleColumns);
    }, [visibleColumns]);

    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);

            return d;
        });
    };
    const formatDate = (value) => {
        if (value instanceof Date) {
            return value.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
        } else if (typeof value === 'string') {
            const date = new Date(value);
            if (!isNaN(date.getTime())) {
                // Check if date is valid
                return formatDate(date); // Recursive call with valid Date object
            } else {
                console.error('Invalid date:', value);
                return 'Invalid Date';
            }
        } else {
            console.error('Invalid date:', value);
            return 'Invalid Date';
        }
    };
    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const clearFilter = () => {
        initFilters(visibleColumns);
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const initFilters = (columns) => {
        const fieldNames = columns.map(column => column.field);
        setGlobalFilterValue('');


        const defaultFilter = {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
        };

        const initialFilters = {
            global: { ...defaultFilter },
        };

        columns.forEach(column => {
            let filterSettings = { ...defaultFilter };
            if (column.dataType === 'date') {
                filterSettings.matchMode = PrimeReact.filterMatchModeOptions.date;
            } else if (column.dataType === 'numeric') {
                filterSettings.matchMode = FilterMatchMode.EQUALS;
            }
            initialFilters[column.field] = {
                operator: FilterOperator.AND,
                constraints: [filterSettings]
            };
        });

        setFilters(initialFilters);

    };

    // const initFilters = () => {
    //     setFilters({
    //         global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    //         name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    //         'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
    //         representative: { value: null, matchMode: FilterMatchMode.IN },
    //         date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
    //         balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    //         status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    //         activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
    //         verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    //     });
    //     setGlobalFilterValue('');
    // };
    // const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    // useEffect(() => {
    //     ProductService.getProductsMini().then((data) => setProducts(data));
    // }, []); 

    const exportCSV = (selectionOnly) => {
        dt.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(({ default: autoTable }) => {
                const doc = new jsPDF.default(0, 0);

                // Assuming visibleColumns contains the column headers and items contains the data rows
                console.log('Visible Columns:', visibleColumns);
                console.log('Items:', items);

                autoTable(doc, { columns: visibleColumns, body: items });

                // Check if any data is present before saving the PDF
                if (items.length > 0) {
                    doc.save('products.pdf');
                } else {
                    console.error('No data available to export to PDF.');
                }
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(items);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'products');
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });

    };
    const handleColumnToggle = (e) => {
        setVisibleColumns(e.value);
        e.value = [];
    };
    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

        setVisibleColumns(orderedSelectedColumns);
    };
    const renderHeader = () => {
        return (
            <div className="d-flex justify-content-between mb-3">
                <div>


                    <MultiSelect value={visibleColumns} options={columns} optionLabel="header" onChange={onColumnToggle} className="w-full sm:w-20rem" display="chip" />{"   "}

                    <Button type="button" icon="pi pi-filter-slash" label="Clear" className='' outlined onClick={clearFilter} />
                </div>
                <span className="d-flex justify-content-between">
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" className='' />
                    <Button type="button" icon="pi pi-file" rounded onClick={() => exportCSV(false)} data-pr-tooltip="CSV" />
                    <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel} data-pr-tooltip="XLS" />
                    <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf} data-pr-tooltip="PDF" />


                </span>

            </div>
        );
    };

    const countryBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt="flag" src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} style={{ width: '24px' }} />
                <span>{rowData.country.name}</span>
            </div>
        );
    };

    const filterClearTemplate = (options) => {
        return <Button type="button" icon="pi pi-times" onClick={options.filterClearCallback} severity="secondary"></Button>;
    };

    const filterApplyTemplate = (options) => {
        return <Button type="button" icon="pi pi-check" onClick={options.filterApplyCallback} severity="success"></Button>;
    };

    const filterFooterTemplate = () => {
        return <div className="px-3 pt-0 pb-3 text-center">Filter by Country</div>;
    };

    const representativeBodyTemplate = (rowData) => {
        const representative = rowData.representative;

        return (
            <div className="flex align-items-center gap-2">
                <img alt={representative.name} src={`https://primefaces.org/cdn/primereact/images/avatar/${representative.image}`} width="32" />
                <span>{representative.name}</span>
            </div>
        );
    };

    const representativeFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={representatives} itemTemplate={representativesItemTemplate} onChange={(e) => options.filterCallback(e.value)} optionLabel="name" placeholder="Any" className="p-column-filter" />;
    };

    const representativesItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={option.name} src={`https://primefaces.org/cdn/primereact/images/avatar/${option.image}`} width="32" />
                <span>{option.name}</span>
            </div>
        );
    };

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date);
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
    };

    const balanceBodyTemplate = (rowData) => {
        return formatCurrency(rowData.balance);
    };

    const balanceFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />;
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const activityBodyTemplate = (rowData) => {
        return <ProgressBar value={rowData.activity} showValue={false} style={{ height: '6px' }}></ProgressBar>;
    };

    const activityFilterTemplate = (options) => {
        return (
            <React.Fragment>
                <Slider value={options.value} onChange={(e) => options.filterCallback(e.value)} range className="m-3"></Slider>
                <div className="flex align-items-center justify-content-between px-2">
                    <span>{options.value ? options.value[0] : 0}</span>
                    <span>{options.value ? options.value[1] : 100}</span>
                </div>
            </React.Fragment>
        );
    };

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.verified, 'text-red-500 pi-times-circle': !rowData.verified })}></i>;
    };

    const verifiedFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="verified-filter" className="font-bold">
                    Verified
                </label>
                <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };


    const header = renderHeader();

    // const data = {
    //     columns: [
    //       { field: 'name', header: 'Name', sortable: true },
    //       { field: 'age', header: 'Age', sortable: true },
    //       // Add more columns as needed
    //     ],
    //     items: [
    //       { name: 'John', age: 30 },
    //       { name: 'Alice', age: 25 },
    //       // Add more data items as needed
    //     ],
    //     // Any additional props for DataTable
    //     paginator: true,
    //     rows: 10,
    //   };


    const renderFilterTemplate = (dataType) => {
        if (dataType === 'date') {
            return dateFilterTemplate;
        } else {
            // Return a default filter template or null if no specific template is defined
            return null;
        }
    };

    const renderBodyTemplate = (dataType) => {
        if (dataType === 'date') {
            return dateBodyTemplate;
        } else {
            // Return a default body template or null if no specific template is defined
            return null;
        }
    };
    const renderActionsColumn = (rowData) => {
        return (
            <div className="action-buttons">
                <Button variant="contained" color="primary" onClick={() => handleEdit(rowData.id)}>
                    Edit
                </Button>
                <Button variant="contained" color="secondary" onClick={() => handleDelete(rowData.id)}>
                    Delete
                </Button>
            </div>
        );
    };
    {/* <Column key={index} className='p-3' field={column.field} header={"Actions"}   body={renderActionsColumn} /> */ }
    return (
        <div className="card custom">
            <DataTable ref={dt} value={items} {...otherProps} header={header} filters={filters} >
                {visibleColumns.map((column, index) => (
                    <Column key={index} className='p-3' field={column.field} header={column.header} sortable
                        filter filterElement={renderFilterTemplate(column.dataType)}
                        body={renderBodyTemplate(column.dataType)}
                    />


                ))}

            </DataTable>


            {/* <DataTable value={customers} paginator resizableColumns showGridlines rows={10} loading={loading} dataKey="id" 
                    filters={filters} globalFilterFields={['name', 'country.name', 'representative.name', 'balance', 'status']} header={header}
                    emptyMessage="No data found." className='p-3'>
                <Column field="name" header="Name" sortable filter filterPlaceholder="Search by name" style={{ minWidth: '12rem',display: visibleColumns.includes('name') ? 'table-cell' : 'none' }} />
                <Column header="Country" filterField="country.name" style={{ minWidth: '12rem',display: visibleColumns.includes('name') ? 'table-cell' : 'none' }} body={countryBodyTemplate}
                    filter filterPlaceholder="Search by country" filterClear={filterClearTemplate} 
                    filterApply={filterApplyTemplate} filterFooter={filterFooterTemplate} />
                <Column header="Agent" filterField="representative" sortable  showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }}
                    body={representativeBodyTemplate} filter filterElement={representativeFilterTemplate} />
                <Column header="Date" filterField="date" sortable={true} dataType="date" style={{ minWidth: '10rem' }} body={dateBodyTemplate} filter filterElement={dateFilterTemplate} />
                <Column header="Balance" filterField="balance" sortable dataType="numeric" style={{ minWidth: '10rem' }} body={balanceBodyTemplate} filter filterElement={balanceFilterTemplate} />
                <Column field="status" header="Status" sortable  filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={statusBodyTemplate} filter filterElement={statusFilterTemplate} />
                <Column field="activity" header="Activity" sortable showFilterMatchModes={false} style={{ minWidth: '12rem' }} body={activityBodyTemplate} filter filterElement={activityFilterTemplate} />
                <Column field="verified" header="Verified" sortable  dataType="boolean" bodyClassName="text-center" style={{ minWidth: '8rem' }} body={verifiedBodyTemplate} filter filterElement={verifiedFilterTemplate} />
            </DataTable> */}


        </div>
    );
}
