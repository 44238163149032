import React, { useState, useEffect } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBCard,
  MDBFile
} from 'mdb-react-ui-kit';
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import { useFormik, Field, ErrorMessage, FormikProvider } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useAuth } from '../../context/AuthContext';

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const taskId = searchParams.get('taskId');
  const axios = useAxiosPrivate();
  const { auth } = useAuth();
  //const { restaurant_Id } = auth;
  const [promotionData, setpromotionData] = useState([]);
  const isUpdateForm = Object.keys(promotionData).length > 0;
  //const isUpdateForm = Object.keys(promotionData).length > 0;
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };


  const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
  const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
  const restaurant_Id = globalAuthString.restaurant_Id?globalAuthString.restaurant_Id:null;
  const restId = restaurant_Id ? restaurant_Id : (localAuthString ? localAuthString.restaurant_Id : null);

  useEffect(() => {
    console.log("estId", restId);
    if (taskId) {
      console.log("ParamId", taskId);

      axios.get(`/promotions/${taskId}`)
        .then(response => {
          console.log("response.data", response.data);
          const data = response.data;

          setpromotionData(data);
          const initialValues = {
            promotion_name: data.promotion_name || '',
            start_date: data.start_date || null,
            end_date: data.end_date || null,
            discount_amount: data.discount_amount || null,
            discount_type: data.discount_type || '',
            restaurant_Id: data.restaurant_Id,
            status: data.status || null

          };
          formik.setValues(initialValues);
        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);

  useEffect(() => {
    console.log("Data to be updated:", promotionData);

  }, [promotionData]);

  const formatDateTime = (date) => {
    const d = new Date(date);
  
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    const seconds = String(d.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };


  const addRecord = async (values) => {

    try {
      console.log("Making a POST request to fooditems with data:", values);

      const response = await axios.post('/promotions', values, config);

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);
      alert("Added Successfully!");

    } catch (error) {
      console.error("Error while making the POST request:", error.message);
      alert("Error Adding Record");
    }
  };

  const updateRecord = async (values) => {

    try {
      values.start_date = formatDateTime(values.start_date);
      values.end_date = formatDateTime(values.end_date);
      console.log(`Making a PUT request to fooditems with data:`, values);

      const response = await axios.put(`/promotions/${promotionData.id}`, values, config);

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);
      alert("Updated Successfully!");

    } catch (error) {

      alert("Error Updating Record");
    }
  };



  const formik = useFormik({
    initialValues: {
      promotion_name: '',
      start_date: null,
      end_date: null,
      discount_amount: null,
      discount_type: '',
      restaurant_Id: restId ? restId : null,
      status: null


    },

    validationSchema: Yup.object({

      promotion_name: Yup.string().required('This field is required'),
      start_date: Yup.date().required('This field is required'),
      end_date: Yup.date().required('This field is required'),
      // discount_amount:Yup.number().required('This field is required'),
      discount_type: Yup.string().required('This field is required'),
      status: Yup.number().required('This field is required'),
    }),
    onSubmit: (values, { resetForm }) => {
      // Handle form submission logic, e.g., send data to the 
      console.log(values);
      isUpdateForm ? updateRecord(values) : addRecord(values);
      navigate("/all-promotions");
    },
  });
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <FormikProvider value={formik}>
          <button type="button" className="btn btn-primary mt-3 ms-5" data-mdb-ripple-init="true">
            <Link to="/promotions" className="text-white text-decoration-none">Promotion List</Link>
          </button>
          < MDBCard className='p-5 mt-3'>
            <form onSubmit={formik.handleSubmit}>
              <MDBRow className='mb-4'>
                <MDBCol>
                  <Form.Group controlId="promotion_name">
                    <label className="form-label" htmlFor="promotion_name">Name</label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      name="promotion_name"
                      value={formik.values.promotion_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="inputclass"
                      isInvalid={formik.touched.promotion_name && formik.errors.promotion_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </MDBCol>
                <MDBCol>
                  <Form.Group controlId="status">
                    <label className="form-label" htmlFor="status">Status</label>
                    <Field
                      as="select"
                      className="browser-default custom-select"
                      id="status"
                      name="status"
                      value={formik.values.status}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.status && formik.errors.status}
                    >
                      <option value="" disabled selected>Select status</option>
                      <option value={1}>Active</option>
                      <option value={0}>Not Active</option>

                    </Field>
                    <small><ErrorMessage name="status" component="div" className="text-danger" /></small>
                  </Form.Group>

                </MDBCol>
              </MDBRow>

              <MDBRow className='mb-4'>
                <MDBCol>
                  <Form.Group controlId="start_date">
                    <label className="form-label" htmlFor="start_date">Start Date</label>
                    <Form.Control
                      type="date"

                      name="start_date"
                      value={formik.values.start_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="inputclass"
                      isInvalid={formik.touched.start_date && formik.errors.start_date}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.start_date}
                    </Form.Control.Feedback>
                  </Form.Group>
                </MDBCol>
                <MDBCol>
                  <Form.Group controlId="end_date">
                    <label className="form-label" htmlFor="end_date">End Date</label>
                    <Form.Control
                      type="date"

                      name="end_date"
                      value={formik.values.end_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="inputclass"
                      isInvalid={formik.touched.end_date && formik.errors.end_date}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.end_date}
                    </Form.Control.Feedback>
                  </Form.Group>

                </MDBCol>
              </MDBRow>

              <MDBRow className='mb-4'>
                <MDBCol>
                  <Form.Group controlId="discount_amount">
                    <label className="form-label" htmlFor="discount_amount">Discount Amount</label>
                    <Form.Control
                      type="number"

                      name="discount_amount"
                      value={formik.values.discount_amount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="inputclass"
                      isInvalid={formik.touched.discount_amount && formik.errors.discount_amount}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.discount_amount}
                    </Form.Control.Feedback>
                  </Form.Group>
                </MDBCol>
                <MDBCol>
                  <Form.Group controlId="discount_type">
                    <label className="form-label" htmlFor="discount_type">Discount Type</label>
                    <Field
                      as="select"
                      className="browser-default custom-select"
                      id="discount_type"
                      name="discount_type"
                      value={formik.values.discount_type}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      isInvalid={formik.touched.discount_type && formik.errors.discount_type}
                    >
                      <option value="" disabled selected>Select discount type</option>
                      <option value="fixed">Fixed</option>
                      <option value="percentage">Percentage</option>

                    </Field>
                    <small><ErrorMessage name="discount_type" component="div" className="text-danger" /></small>
                  </Form.Group>

                </MDBCol>
              </MDBRow>


              <button className='mb-4 mt-5 btn btn-primary w-100' type='submit' >
                {isUpdateForm ? 'Update' : 'Add'}
              </button>
            </form>
          </MDBCard>
        </FormikProvider>
      </DashboardLayout>
    </>
  );
}