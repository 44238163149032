import React ,{useState,useEffect}from 'react';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCheckbox,
  MDBBtn,
  MDBCard,
  MDBFile
} from 'mdb-react-ui-kit';
import {Link, useLocation , useNavigate} from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import { useFormik,Field, ErrorMessage,FormikProvider } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useAuth } from '../../context/AuthContext';

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const taskId = searchParams.get('taskId');
  const axios = useAxiosPrivate();
  const { auth } = useAuth();
  //const {restaurant_Id} = auth;
  const [categoryData, setCategoryData] = useState([]);
  const isUpdateForm = Object.keys(categoryData).length > 0;

  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  };

  const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
  const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
  const restaurant_Id = globalAuthString.restaurant_Id?globalAuthString.restaurant_Id:null;
  const restId = restaurant_Id ? restaurant_Id : (localAuthString ? localAuthString.restaurant_Id : null);

  useEffect(() => {
    if (taskId) {
      console.log("ParamId", taskId);
      axios.get(`/food_item_categories/${taskId}`)
        .then(response => {
          console.log("response.data",response.data);
          const data = response.data;
   
          setCategoryData(data);
          const initialValues = {
            name: data.name || '',
            description: data.description || '',
            is_active: data.is_active || null,
            categoryImage: data.image_path || '',
            restaurant_Id:data.restaurant_Id || '',
          };
          formik.setValues(initialValues);
        })
        .catch(error => {
          console.error('Error fetching record:', error);
        });
    }
  }, [taskId]);

  useEffect(() => {
    console.log("Data to be updated:", categoryData);

  }, [categoryData]);


  const addRecord = async (values) => {
     
    try {
      console.log("Making a POST request to fooditems with data:", values);

      const response = await axios.post('/food_item_categories', values, config);

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);
      alert("Added Successfully!");
      setTimeout(function () {

        // handleButtonClick();
      }, 3000);
    } catch (error) {
      console.error("Error while making the POST request:", error.message);
      alert("Error Adding Record");
    }
  };

  const updateRecord = async (values) => {
 
    try {
      console.log(`Making a PUT request to fooditems with data:`, values);

      const response = await axios.put(`/food_item_categories/${categoryData.id}`, values, config);

      console.log("Response status:", response.status);
      console.log("Response data:", response.data);
      alert("Updated Successfully!");
      setTimeout(function () {

        //  handleButtonUpdate();
      }, 3000);
    } catch (error) {

      alert("Error Updating Record");
    }
  };



  const formik = useFormik({
    initialValues: {
      name: categoryData ? categoryData.name : '',
      description: categoryData ? categoryData.description : '',
      is_active: categoryData ? categoryData.is_active : null,
      categoryImage: categoryData ? categoryData.image_path : '',
      restaurant_Id:restId? restId: '',
  
    },

    validationSchema: Yup.object({
      name: Yup.string().required('This field is required'),
      is_active: Yup.number().required('This field is required'),
      description: Yup.string().required('This field is required'),
      categoryImage: Yup.mixed().required('This field is required'),
     
    }),
    onSubmit: (values, { resetForm }) => {
      // Handle form submission logic, e.g., send data to the server
      isUpdateForm ? updateRecord(values) : addRecord(values);
      resetForm();
      navigate(`/food-categories`);
    },
  });
  return (
    <>
    <DashboardLayout>
    <DashboardNavbar />
    <FormikProvider value={formik}>
    <button type="button" className="btn btn-primary mt-3 ms-5" data-mdb-ripple-init="true">
  <Link to="/food-categories" className="text-white text-decoration-none">Food Category List</Link>
</button>
    < MDBCard className='p-5 mt-3'>
    <form onSubmit={formik.handleSubmit}>
            <MDBRow className='mb-4'>
              <MDBCol>
                <Form.Group controlId="title">
                <label className="form-label" htmlFor="name">Name</label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="inputclass"
                    isInvalid={formik.touched.name && formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </MDBCol>
              <MDBCol>
                <Form.Group controlId="is_active">
                  <label className="form-label" htmlFor="is_active">Status</label>
                  <Field
                    as="select"
                    className="browser-default custom-select"
                    id="is_active"
                    name="is_active"
                    value={formik.values.is_active}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    isInvalid={formik.touched.is_active && formik.errors.is_active}
                  >
                    <option value="" disabled>Select status</option>
                    <option value={1}>Active</option>
                    <option value={0}>Not Active</option>
              
                  </Field>
                  <small><ErrorMessage name="is_active" component="div" className="text-danger" /></small>
                </Form.Group>

              </MDBCol>
            </MDBRow>
      <MDBRow className='mb-4'>
              <MDBCol>
                <Form.Group controlId="description">
                  <label className="form-label" htmlFor="description">Description</label>
                  <textarea
                    id="description"
                    name="description"
                    rows={1}
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="form-control"
                  // You can add more props as needed
                  />
                  <small><ErrorMessage name="description" component="div" className="text-danger" /></small>
                </Form.Group>
              </MDBCol>
              <MDBCol>
              <Form.Group controlId="categoryImage">
              <label className="form-label" htmlFor="categoryImage">Featured Image</label>
                      <input
                        type="file"
                        name="categoryImage"
                        accept="image/*"
                        onChange={(event) => formik.setFieldValue('categoryImage', event.currentTarget.files[0])}
                        className={` inputclass form-control ${formik.touched.categoryImage && formik.errors.categoryImage ? 'is-invalid' : ''
                          }`}
                      />
                      <div className="invalid-feedback">{formik.errors.categoryImage}</div>
                    </Form.Group>
              </MDBCol>
            </MDBRow>
      
     <button className='mb-4 mt-5 btn btn-primary w-100' type='submit' >
     {isUpdateForm ? 'Update' : 'Add'}
      </button>
    </form>
    </MDBCard>
    </FormikProvider>
    </DashboardLayout>
    </>
  );
}