import React, {useState, useEffect } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBCard,

} from 'mdb-react-ui-kit';
import MDBadge from "components/MDBadge";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Link } from 'react-router-dom';
import DataTable from "examples/PrimeReactDatatable";
import OrdersData from "layouts/Orders/data";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
// Data
import reportsBarChartData, { multi, sales } from "layouts/dashboard/data/reportsBarChartData";
import Orders from "layouts/Orders/Orders";
import Employees from "layouts/Employees";
import Customers from "layouts/Customers";
import useAxiosPrivate from "../../hook/useAxiosPrivate";

export default function App() {
  const axios = useAxiosPrivate();
  const [selectedOption, setSelectedOption] = useState("");
  const [salesData, setSalesData] = useState({ labels: [], datasets: { label: "", data: [] } });
  const [monthlyData, setMonthlyData] = useState({});
  const [orderResponse, setOrderResponse] = useState([]);
  const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
  const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
  const restaurant_Id = globalAuthString.restaurant_Id?globalAuthString.restaurant_Id:null;
  const restId = restaurant_Id ? restaurant_Id : (localAuthString ? localAuthString.restaurant_Id : null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await axios.get(`/order/restaurant/${restId}`);
        const data = response.data.data;
        console.log("fetch response", data);
        setOrderResponse(data);
      } catch (error) {
        console.error('Error fetching Orders:', error);
      }
    };
  
    fetchOrder();
  }, []);
  
  const handleSelectedOption = (e) => {
    setSelectedOption(e.target.value);
  }
  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await axios.get('/api/sales'); // Assuming the API endpoint is defined in your backend server
        const formattedData = formatSalesData(response.data);
        setSalesData(formattedData);
      } catch (error) {
        console.error('Error fetching sales data:', error);
      }
    };

    fetchSalesData();
  }, []);

  const formatSalesData = (data) => {
    const labels = data.labels;
    const datasets = { label:"Sales", data: data.datasets };
    return { labels, datasets };
  };






  useEffect(() => {
    const fetchMonthlyData = async () => {
      try {
        const response = await axios.get(`/order/restaurant/${restId}`);
        const orders = response.data.data;

        console.log("monthlyorders",orders.data);
       

        // Process orders data to calculate monthly sales totals
        const monthlySalesData = calculateMonthlySales(orders);
        console.log("monthlysakesorders",monthlySalesData);
        // Update state with the monthly sales data
        setMonthlyData(monthlySalesData);
        //setLoading(false);
      } catch (error) {
        console.error("Error fetching monthly data:", error);
        //setLoading(false);
      }
    };

    fetchMonthlyData();
  }, []);

  // Helper function to calculate monthly sales totals
  const calculateMonthlySales = (orders) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    // Initialize sales data for each month
    const monthlySales = months.map(month => ({ month, total: 0 }));
  
    // Iterate over orders and accumulate sales totals for each month
    orders.forEach(order => {
      const orderDate = new Date(order.createdAt);
      const monthIndex = orderDate.getMonth();
      const grandTotal = order.grandTotal;
      monthlySales[monthIndex].total += grandTotal;
    });
  
    // Extract sales totals for each month
    const data = monthlySales.map(entry => entry.total);
  
    // Return sales data with month names as labels
    return { labels: months, datasets: { label: "Sales", data } };
  };
  
  useEffect(() => {

  }, [monthlyData]);




  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />

        < MDBCard className='p-5 mt-5' >
          <MDBox
            mx={-2}
            mt={-9}
            mb={5}
            py={3}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
          >
            <MDTypography variant="h6" color="white">
              Reports
            </MDTypography>
          </MDBox>
          <form>
            {/* <MDBRow className='mb-4'>
              <MDBCol>
                <label className="form-label" htmlFor="package">Start Date</label>
                <MDBInput id='form6Example1' type='date' />
              </MDBCol>
              <MDBCol>
                <label className="form-label" htmlFor="package">End Date</label>
                <MDBInput id='form6Example2' type='date' />
              </MDBCol>
            </MDBRow> */}
            <MDBRow className='mb-4'>
              <MDBCol>

                <label className="form-label" htmlFor="package">Select Report Type</label>
                <select className="browser-default custom-select" id="package" onChange={handleSelectedOption}>
                  <option selected></option>
                  <option value="Customers">Customers Report</option>
                  <option value="Orders">Orders Report</option>
                  <option value="Sales">Sales Report</option>
                  <option value="Employees">Employees Management Report</option>
                  {/* <option value="Inventory">Inventory Report</option> */}
                </select>

              </MDBCol>
              {/* <MDBCol>

                <label className="form-label" htmlFor="package">Select Time</label>
                <select className="browser-default custom-select" id="package" onClick={console.log("data", OrdersData)}>
                  <option selected></option>
                  <option value="Last 30 Days<">Last 30 Days</option>
                  <option value="Last 14 Days">Last 14 Days</option>
                  <option value="Last 7 Days">Last 7 Days</option>
                </select>

              </MDBCol> */}
            </MDBRow>

            {/* <MDBBtn className='my-4' type='submit' block>
              Apply
            </MDBBtn> */}
          </form>
        </MDBCard>
       {/* { selectedOption === "Financial" && <Grid item xs={12} md={6} lg={4}>
          <MDBox my={5}>
            <ReportsBarChart
              color="success"
              title="Financial Report"
              description="Last Campaign Performance"
              date="just updated"
              chart={multi}
              ReportChart={true}
            />
          </MDBox>
        </Grid>} */}

        { selectedOption === "Sales" &&
        <Grid item xs={12} md={6} lg={4}>
          <MDBox mb={3}>
            <ReportsLineChart
              color="info"
              title="Sales Report"
              description=""
              date="updated 4 min ago"
              chart={monthlyData}
              ReportChart={monthlyData}
            />
          </MDBox>
        </Grid>
        }
        { selectedOption === "Orders" &&
        <MDBox pt={6} pb={3}>

          <Grid container spacing={6}>
            <Grid item xs={12}>

              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>

                  <MDTypography variant="h6" gutterBottom>
                    Orders Report
                  </MDTypography>


                </MDBox>
                <MDBox pt={1}>
                  {/* <DataTable
                    tableData={OrderData}
                  /> */}
                  <Orders report={true}/>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
}

{ selectedOption === "Customers" &&
        <MDBox pt={6} pb={3}>

          <Grid container spacing={6}>
            <Grid item xs={12}>

              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>

                  <MDTypography variant="h6" gutterBottom>
                    Customers Report
                  </MDTypography>


                </MDBox>
                <MDBox pt={1}>
            
                  <Customers report={true}/>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
}


{ selectedOption === "Employees" &&
        <MDBox pt={6} pb={3}>

          <Grid container spacing={6}>
            <Grid item xs={12}>

              <Card>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>

                  <MDTypography variant="h6" gutterBottom>
                    Employees Report
                  </MDTypography>


                </MDBox>
                <MDBox pt={1}>
               
                  <Employees report={true}/>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
}
      </DashboardLayout>
    </>
  );
}