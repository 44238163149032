import React, { useState, useEffect } from 'react';
import {
    MDBRow,
    MDBCol,
    MDBInput,
    MDBCheckbox,
    MDBBtn,
    MDBCard,
    MDBFile
} from 'mdb-react-ui-kit';
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useAxiosPrivate from "../../hook/useAxiosPrivate";
import { useFormik, Field, ErrorMessage, FormikProvider } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useAuth } from '../../context/AuthContext';

export default function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const taskId = searchParams.get('taskId');
    const axios = useAxiosPrivate();
    const { auth } = useAuth();
    const { restaurantDetail } = auth;
    const [areaData, setareaData] = useState([]);
    const [cities, setcities] = useState([]);
    const isUpdateForm = Object.keys(areaData).length > 0;
    //const isUpdateForm = Object.keys(areaData).length > 0;
    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    };

    const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
    const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
    const restaurantId = globalAuthString.restaurant_Id ? globalAuthString.restaurant_Id : null;
    const restId = restaurantId ? restaurantId : (localAuthString ? localAuthString.restaurantId : null);

    const fetchcities = () => {
        axios.get(`/city`)
            .then(response => {

                const data = response.data.data;
                console.log(data);
                setcities(data);

            })
            .catch(error => console.error('Error fetching cities:', error));
    };
    useEffect(() => {

        fetchcities();
    }, []);

    useEffect(() => {
        console.log("Cities:", cities);
    }, [cities]);

    useEffect(() => {
        if (taskId) {
            console.log("ParamId", taskId);
            axios.get(`/area/${taskId}`)
                .then(response => {
                    console.log("response.data", response.data);
                    const data = response.data.data;

                    setareaData(data);
                    const initialValues = {
                        name: data.name || '',
                        is_active: data.is_active || null,
                        city_id: data.city_id || null,
                        restaurant_Id: data.restaurant_Id || null

                    };
                    formik.setValues(initialValues);
                })
                .catch(error => {
                    console.error('Error fetching record:', error);
                });
        }
    }, [taskId]);

    useEffect(() => {
        console.log("Data to be updated:", areaData);

    }, [areaData]);


    const addRecord = async (values) => {

        try {
            console.log("Making a POST request to fooditems with data:", values);

            const response = await axios.post('/area', values, config);

            console.log("Response status:", response.status);
            console.log("Response data:", response.data);
            alert("Added Successfully!");
            setTimeout(function () {

                // handleButtonClick();
            }, 3000);
        } catch (error) {
            console.error("Error while making the POST request:", error.message);
            alert("Error Adding Record");
        }
    };

    const updateRecord = async (values) => {

        try {
            console.log(`Making a PUT request to fooditems with data:`, values);

            const response = await axios.put(`/area/${areaData.id}`, values, config);

            console.log("Response status:", response.status);
            console.log("Response data:", response.data);
            alert("Updated Successfully!");
            setTimeout(function () {

                //  handleButtonUpdate();
            }, 3000);
        } catch (error) {

            alert("Error Updating Record");
        }
    };



    const formik = useFormik({
        initialValues: {
            name: areaData ? areaData.name : '',
            is_active: areaData ? areaData.is_active : null,
            city_id: areaData ? areaData.city_id : null,
            restaurant_Id: restId

        },

        validationSchema: Yup.object({
            name: Yup.string().required('This field is required'),
            is_active: Yup.number().required('This field is required'),
            city_id: Yup.number().required('This field is required'),

        }),
        onSubmit: (values) => {
            // Handle form submission logic, e.g., send data to the server
            isUpdateForm ? updateRecord(values) : addRecord(values);
            navigate(`/areas`);
        },
    });
    return (
        <>
            <DashboardLayout>
                <DashboardNavbar />
                <FormikProvider value={formik}>
                    <button type="button" className="btn btn-primary mt-3 ms-5" data-mdb-ripple-init="true">
                        <Link to="/areas" className="text-white text-decoration-none">Area List</Link>
                    </button>
                    < MDBCard className='p-5 mt-3'>
                        <form onSubmit={formik.handleSubmit}>
                            <MDBRow className='mb-4'>
                                <MDBCol>
                                    <Form.Group controlId="city_id">
                                        <label className="form-label" htmlFor="city_id">Category</label>
                                        <Field
                                            as="select"
                                            className="browser-default custom-select"
                                            id="city_id"
                                            name="city_id"
                                            value={formik.values.city_id}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.city_id && formik.errors.city_id}
                                        >
                                            <option value="" disabled selected>Select City</option>
                                            {cities.map(category => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </Field>
                                        <small><ErrorMessage name="categoryId" component="div" className="text-danger" /></small>
                                    </Form.Group>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='mb-4'>
                                <MDBCol>
                                    <Form.Group controlId="title">
                                        <label className="form-label" htmlFor="name">Name</label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Name"
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            className="inputclass"
                                            isInvalid={formik.touched.name && formik.errors.name}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </MDBCol>
                                <MDBCol>
                                    <Form.Group controlId="is_active">
                                        <label className="form-label" htmlFor="is_active">Status</label>
                                        <Field
                                            as="select"
                                            className="browser-default custom-select"
                                            id="is_active"
                                            name="is_active"
                                            value={formik.values.is_active}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isInvalid={formik.touched.is_active && formik.errors.is_active}
                                        >
                                            <option value="" disabled selected>Select status</option>
                                            <option value={1}>Active</option>
                                            <option value={0}>Not Active</option>

                                        </Field>
                                        <small><ErrorMessage name="is_active" component="div" className="text-danger" /></small>
                                    </Form.Group>

                                </MDBCol>
                            </MDBRow>

                            <button className='mb-4 mt-5 btn btn-primary w-100' type='submit' >
                                {isUpdateForm ? 'Update' : 'Add'}
                            </button>
                        </form>
                    </MDBCard>
                </FormikProvider>
            </DashboardLayout>
        </>
    );
}