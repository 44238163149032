
import Grid from "@mui/material/Grid";
import React, {useState, useEffect} from 'react';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import Orders from "layouts/Orders/Orders";
import useAxiosPrivate from "../../hook/useAxiosPrivate";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;

  const axios = useAxiosPrivate();
 

  const [ordersCount, setOrdersCount] = useState(0);
  const [customersCount, setCustomersCount] = useState(0);
  const [employeesCount, setEmployeesCount] = useState(0);
  const [monthlyData, setMonthlyData] = useState({});
  const [monthlyCustomerData, setMonthlyCustomerData] = useState({});
  const [monthlyOrdersData, setMonthlyOrdersData] = useState({});
  const [loading, setLoading] = useState(true); 
  
  const localAuthString = JSON.parse(sessionStorage.getItem('kitchenAdminAuth'));
  const globalAuthString = JSON.parse(sessionStorage.getItem('auth'));
  const restaurant_Id = globalAuthString.restaurant_Id?globalAuthString.restaurant_Id:null;
  const restId = restaurant_Id ? restaurant_Id : (localAuthString ? localAuthString.restaurant_Id : null);

  useEffect(() => {
    const fetchMonthlyData = async () => {
      try {
        const response = await axios.get(`/order/restaurant/${restId}`);
        const orders = response.data.data;

        console.log("monthlyorders",orders.data);
       

        // Process orders data to calculate monthly sales totals
        const monthlySalesData = calculateMonthlySales(orders);
        console.log("monthlysakesorders",monthlySalesData);
        // Update state with the monthly sales data
        setMonthlyData(monthlySalesData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching monthly data:", error);
        setLoading(false);
      }
    };

    fetchMonthlyData();
  }, []);

  // Helper function to calculate monthly sales totals
  const calculateMonthlySales = (orders) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    
    // Initialize sales data for each month
    const monthlySales = months.map(month => ({ month, total: 0 }));
  
    // Iterate over orders and accumulate sales totals for each month
    orders.forEach(order => {
      const orderDate = new Date(order.createdAt);
      const monthIndex = orderDate.getMonth();
      const grandTotal = order.grandTotal;
      monthlySales[monthIndex].total += grandTotal;
    });
  
    // Extract sales totals for each month
    const data = monthlySales.map(entry => entry.total);
  
    // Return sales data with month names as labels
    return { labels: months, datasets: { label: "Sales", data } };
  };
  



  const fetchUsers = () => {
    axios.get(`/users/employee/restaurant/${restId}`)
      .then(response => {

        const data = response.data;
        console.log(data);
        setEmployeesCount(data.data.length);

      })
      .catch(error => console.error('Error fetching users:', error));
 
    };

    const fetchCustomers = () => {
      axios.get(`/order/customers/restaurant/${restId}`)
        .then(response => {
  
          const data = response.data;
          console.log(data);
          setCustomersCount(data.data.length);


          const monthlyCustomersData = calculateCountOverTime(data.data, 'Customers');
          console.log("monthlyCustomersData",monthlyCustomersData);
          // Update state with the monthly sales data
          setMonthlyCustomerData(monthlyCustomersData);
  
        })
        .catch(error => console.error('Error fetching customers:', error));
   
      };

      const fetchOrders = () => {
        axios.get(`/order/restaurant/${restId}`)
          .then(response => {
    
            const data = response.data;
            console.log(data);
            setOrdersCount(data.data.length);
            console.log("orders",data.data.length);

            const monthlyOrdersData = calculateOrderCountOverTime(data.data, 'Orders');
            console.log("monthlyOrderssData",monthlyOrdersData);
            // Update state with the monthly sales data
            setMonthlyOrdersData(monthlyOrdersData);
          })
          .catch(error => console.error('Error fetching orders:', error));
     
        };

      useEffect(() => {


        fetchUsers();
        fetchCustomers();
        fetchOrders();
      }, []);
    
      useEffect(() => {

      }, [employeesCount,customersCount,ordersCount,monthlyData,monthlyCustomerData,monthlyOrdersData]);



      const calculateCountOverTime = (data, key) => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        
        // Initialize count data for each month
        const countOverTime = months.map(month => ({ month, count: 0 }));
        
        // Iterate over data and accumulate counts for each month
        data.forEach(item => {
          const date = new Date(item.registeredAt);
          const monthIndex = date.getMonth();
          countOverTime[monthIndex].count += 1; // Assuming each item corresponds to one count
        });
        
        // Extract count for each month
        const counts = countOverTime.map(entry => entry.count);
        
        // Return count data with month names as labels
        return { labels: months, datasets: { label: key, data: counts } };
      };
      
      const calculateOrderCountOverTime = (data, key) => {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        
        // Initialize count data for each month
        const countOverTime = months.map(month => ({ month, count: 0 }));
        
        // Iterate over data and accumulate counts for each month
        data.forEach(item => {
          const date = new Date(item.createdAt);
          const monthIndex = date.getMonth();
          countOverTime[monthIndex].count += 1; // Assuming each item corresponds to one count
        });
        
        // Extract count for each month
        const counts = countOverTime.map(entry => entry.count);
        
        // Return count data with month names as labels
        return { labels: months, datasets: { label: key, data: counts } };
      };


 
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="shopping_cart"
                title="Orders"
                count={ordersCount}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="group"
                title="Total Customers"
                count={customersCount}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="monetization_on"
                title="Revenue"
                count="0"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid> */}
          <Grid item xs={12} md={6} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="store_front"
                title="Employees"
                count={employeesCount}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
                {/* <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="restaurant_menu"
                title="Menus"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox> */}
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
              <ReportsLineChart
                  color="info"
                  title="sales"
                  description={
                    <>
                      Increase in sales over time.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={monthlyData}
               
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="customers"
                  description={
                    <>
                      Increase in customers over time.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={monthlyCustomerData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {/* <ReportsLineChart
                  color="dark"
                  title="orders"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                /> */}
                 <ReportsLineChart
                  color="secondary"
                  title="Orders"
                  description={
                    <>
                      Increase in orders over time.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={monthlyOrdersData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
              <OrdersOverview />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>

    </DashboardLayout>
  );
}

export default Dashboard;
